import { Button, Grid, makeStyles } from "@material-ui/core";
import { getLanguageCode, languageCodes, setLocale } from "@utils/langUtil";
import theme from "@config/theme";
import React, { useContext } from "react";
import { useSelector } from "react-redux";
import { AppContext } from "@contextProviders/AppContextProvider";
import { getTranslation } from "src/store/languageSlice";

const useStyles = makeStyles({
    bold: {
        backgroundColor: "transparent",
        "&:hover": {
            background: "transparent",
        },
        padding: 0,
        fontSize: 14,
        fontWeight: "bold",
        minWidth: 0,
    },
    linkDivider: {
        padding: "0px 5px",
        color: "black",
    },
    link: {
        textDecoration: "underline",
        backgroundColor: "transparent",
        "&:hover": {
            background: "transparent",
        },
        padding: 0,
        fontSize: 14,
        color: theme.palette.link.color,
        minWidth: 0,
    },
});

const LanguageSelect = () => {
    const { store } = useContext(AppContext);
    const t = useSelector(getTranslation);
    const classes = useStyles(store);
    const langCode = getLanguageCode();
    const handleLanguageChange = (value) => {
        if (languageCodes[value] !== langCode) {
            setLocale(value);
        }
    };
    return (
        <Grid item>
            {Object.keys(languageCodes).map((language, i) => (
                <>
                    <Button
                        key={language}
                        button
                        onClick={() => handleLanguageChange(language)}
                        className={
                            langCode === languageCodes[language] ? classes.bold : classes.link
                        }
                    >
                        {language === "SE" ? t.swedish : t.english}
                    </Button>
                    {i === 0 && <span className={classes.linkDivider}>|</span>}
                </>
            ))}
        </Grid>
    );
};

export default LanguageSelect;
