import { ButtonGroup } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    root: {
        height: 49,
    },
});

const WOButtonGroup = (props) => {
    const classes = useStyles();
    const { children, color = "secondary", variant = "contained" } = props;

    return (
        <ButtonGroup
            color={color}
            variant={variant}
            disableElevation={true}
            fullWidth
            className={classes.root}
        >
            {children}
        </ButtonGroup>
    );
};

WOButtonGroup.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.oneOf(["primary", "secondary"]),
    variant: PropTypes.oneOf(["contained", "outlined"]),
};

export default WOButtonGroup;
