import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
    makeStyles,
    Typography,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    List,
} from "@material-ui/core";
import theme from "@config/theme";
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { ORDER_TYPE } from "@utils/localStorageUtil";
import { AppContext } from "@contextProviders/AppContextProvider";
import { getOrderTypeTranslation, ORDER_TYPES } from "@utils/commonUtil";
import { writeStorage } from "@rehooks/local-storage";
import { getTranslation } from "src/store/languageSlice";
import LanguageSelect from "@shared/LanguageSelect";

const useStyles = makeStyles({
    banner: {
        bottom: 0,
        position: "fixed",
        width: "100%",
    },
    dialogContent: {
        minWidth: 500,
        [theme.breakpoints.down("xs")]: {
            minWidth: "100vw",
            paddingBottom: 25,
        },
    },
    list: {
        marginBottom: 10,
    },
    listItemContainer: {
        display: "flex",
        alignItems: "center",
        width: "calc(100% - 10px)",
        height: 50,
    },
    listItemPrimaryText: {
        fontSize: 16,
        fontWeight: 600,
        color: "#FFF",
    },
    listItem: {
        borderRadius: 4,
        padding: 15,
        marginBottom: 20,
        "&:last-child": {
            marginBottom: 0,
        },
        backgroundColor: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: theme.palette.secondary.main,
        },
    },
    caption: {
        margin: 10,
        fontSize: 20,
        fontWeight: 600,
    },
    imageConatiner: {
        width: 50,
        marginRight: 20,
    },
    itemImage: {
        position: "relative",
        float: "left",
    },
});

const OrderTypeSelector = () => {
    const [orderTypeSelector, setOrderTypeSelector] = useState(true);
    const t = useSelector(getTranslation);
    const { store } = useContext(AppContext);
    const classes = useStyles(store);
    const handleOrderTypeSelection = async (orderType) => {
        writeStorage(ORDER_TYPE, orderType);
        setOrderTypeSelector(false);
    };

    return (
        <div className={classes.banner}>
            <Dialog
                open={orderTypeSelector}
                PaperProps={{
                    style: {
                        height: "auto",
                        maxHeight: 400,
                        position: "fixed",
                        margin: 0,
                    },
                }}
                disableBackdropClick
            >
                <DialogTitle>
                    <Typography align="center" className={classes.caption}>
                        {t.welcome_to}
                        <br /> {get(store, "name")}!
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <List className={classes.list}>
                        {Object.values(ORDER_TYPES).map((orderType) => (
                            <ListItem
                                key={orderType}
                                button
                                divider={true}
                                className={classes.listItem}
                                onClick={() => handleOrderTypeSelection(orderType)}
                            >
                                <div className={classes.listItemContainer}>
                                    <div className={classes.imageConatiner}>
                                        <img
                                            src={`/assets/images/store/${orderType}.svg`}
                                            alt={t.card}
                                            className={classes.itemImage}
                                        />
                                    </div>
                                    <ListItemText
                                        primary={getOrderTypeTranslation(orderType, t)}
                                        primaryTypographyProps={{
                                            noWrap: true,
                                            className: classes.listItemPrimaryText,
                                        }}
                                    />
                                </div>
                            </ListItem>
                        ))}
                    </List>
                    <LanguageSelect />
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default OrderTypeSelector;
