import PropTypes from "prop-types";
import React from "react";

const CoverImage = (props) => {
    const { coverImageUrl } = props;

    if (coverImageUrl) {
        return (
            <div
                style={{
                    backgroundImage: `url(${coverImageUrl})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    height: 235,
                }}
            ></div>
        );
    } else {
        return <div style={{ paddingTop: 50 }} />;
    }
};

CoverImage.propTypes = {
    coverImageUrl: PropTypes.string.isRequired,
};

export default CoverImage;
