import { AppBar, Button, Divider, Grid, Typography, withWidth } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import CopyrightIcon from "@material-ui/icons/Copyright";
import { get } from "lodash";
import { AppContext } from "@contextProviders/AppContextProvider";
import theme from "@config/theme";
import { getTranslation } from "src/store/languageSlice";
import LanguageSelect from "@shared/LanguageSelect";

const useStyles = makeStyles({
    appBar: {
        top: "auto",
        bottom: 0,
        background: "#FFF",
        paddingLeft: 10,
        paddingRight: 10,
        height: 60,
        marginTop: 50,
    },
    copyRightContainer: {
        display: "flex",
        alignItems: "center",
        flex: 1,
    },
    container: {
        padding: "10px 5px",
        display: "flex",
    },
    links: {
        width: "100%",
    },
    link: {
        textDecoration: "underline",
        backgroundColor: "transparent",
        "&:hover": {
            background: "transparent",
        },
        padding: 0,
        fontSize: 14,
        color: theme.palette.link.color,
        minWidth: 0,
    },
    termsConditions: {
        float: "right",
        right: 10,
        position: "absolute",
    },
    copyrightText: {
        fontWeight: "bold",
        fontSize: 14,
    },
    divider: {
        margin: 0,
    },
    linkDivider: {
        padding: "0px 5px",
        color: "black",
    },
    appVersion: { right: 10, position: "absolute" },
    footerText: {
        left: 0,
        width: "100%",
    },
});

const CopyrightFooter = (props) => {
    const classes = useStyles();
    const { position } = props;
    const t = useSelector(getTranslation);
    const location = useLocation();
    const disableTermsLink =
        location.pathname.includes("terms-and-conditions") ||
        location.pathname.includes("privacy-policy");
    const { store } = useContext(AppContext);

    return (
        <AppBar
            position={position ? position : "relative"}
            className={classes.appBar}
            elevation={0}
        >
            <Divider variant="middle" className={classes.divider} />
            <div className={classes.container}>
                <div className={classes.footerText}>
                    {get(store, "customFooterText") ? (
                        <Typography color="primary" className={classes.copyrightText}>
                            {get(store, "customFooterText")}
                        </Typography>
                    ) : (
                        <div className={classes.copyRightContainer}>
                            <CopyrightIcon color="primary" fontSize="small" />
                            <Typography color="primary" className={classes.copyrightText}>
                                {t.copyright_text}
                            </Typography>
                        </div>
                    )}
                    <Grid container justify="space-between" alignItems="center">
                        <LanguageSelect />
                        <Grid item justify="flex-end">
                            <Button
                                component={Link}
                                to={{ pathname: "/terms-and-conditions" }}
                                target="_blank"
                                className={classes.link}
                                disabled={disableTermsLink}
                            >
                                {t.terms_and_conditions}
                            </Button>
                            {!disableTermsLink && <span className={classes.linkDivider}>|</span>}
                            <Button
                                component={Link}
                                to={{ pathname: "/privacy-policy" }}
                                target="_blank"
                                className={classes.link}
                                disabled={disableTermsLink}
                            >
                                {t.privacy_policy}
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                <div className={classes.appVersion}>
                    <Typography color="primary" className={classes.copyrightText}>
                        {process.env.REACT_APP_DISPLAY_VERSION}
                    </Typography>
                </div>
            </div>
        </AppBar>
    );
};

CopyrightFooter.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
    position: PropTypes.oneOf(["absolute", "fixed", "relative", "static", "sticky"]),
};

export default withWidth()(CopyrightFooter);
