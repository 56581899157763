import { createSlice } from "@reduxjs/toolkit";
import { isEmpty } from "lodash-es";
import LocalizedStrings from "react-localization";

export const languageSlice = createSlice({
    name: "language",
    initialState: {
        locale: "SE",
        translation: {},
    },
    reducers: {
        setLanguage: (state, action) => {
            state.locale = action.payload.locale;
            state.translation = action.payload.translation;
        },
    },
});

export const { setLanguage } = languageSlice.actions;

export const getTranslation = (state) => {
    const { locale, translation } = state.language;
    const t = new LocalizedStrings({ [locale]: translation });
    t.setLanguage(locale);
    return t;
};

export const isLanguageSet = (state) => !isEmpty(state.language.translation);

export default languageSlice.reducer;
