import React, { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import CustomRoute from "./CustomRoute";
import Loader from "@shared/Loader";

const Groups = lazy(() => import("@pages/Groups"));
const Products = lazy(() => import("@pages/Products"));
const Checkout = lazy(() => import("@pages/Checkout"));
const DeliveryInfo = lazy(() => import("@pages/DeliveryInfo"));
const Payment = lazy(() => import("@pages/Payment"));
const PaymentComplete = lazy(() => import("@pages/PaymentComplete"));
const NotFound = lazy(() => import("@pages/NotFound"));
const PolicyPage = lazy(() => import("@pages/PolicyPage"));
const PaymentType = lazy(() => import("@pages/PaymentType"));
const TermsAndConditions = lazy(() => import("@pages/TermsAndConditions"));
const ViewReceipt = lazy(() => import("@pages/ViewReceipt"));
const OrderStatus = lazy(() => import("@pages/OrderStatus"));
const PrivacyPolicy = lazy(() => import("@pages/PrivacyPolicy"));
const Redirect = lazy(() => import("@pages/Redirect"));

const ROUTES = [
    {
        path: "/product-group/:productGroupId/products",
        component: Products,
        cartItemsRequired: false,
        shopOpenRequired: false,
    },
    {
        path: "/checkout",
        component: Checkout,
        cartItemsRequired: true,
        shopOpenRequired: true,
    },
    {
        path: "/delivery-info",
        component: DeliveryInfo,
        cartItemsRequired: true,
        shopOpenRequired: true,
    },
    {
        path: "/",
        component: Groups,
        cartItemsRequired: false,
        shopOpenRequired: false,
    },
    {
        path: "/payment",
        component: Payment,
        cartItemsRequired: true,
        shopOpenRequired: true,
    },
    {
        path: "/payment-complete",
        component: PaymentComplete,
    },
    {
        path: "/payment-type",
        component: PaymentType,
        cartItemsRequired: true,
        shopOpenRequired: true,
    },
    {
        path: "/policy-info",
        component: PolicyPage,
    },
    {
        path: "/terms-and-conditions",
        component: TermsAndConditions,
    },
    {
        path: "/view-receipt",
        component: ViewReceipt,
    },
    {
        path: "/view-order-status",
        component: OrderStatus,
    },
    {
        path: "/privacy-policy",
        component: PrivacyPolicy,
    },
    {
        path: "/r",
        component: Redirect,
        cartItemsRequired: false,
        shopOpenRequired: false,
    },
];

const Routes = () => {
    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                {ROUTES.map((route, index) => (
                    <CustomRoute
                        key={index}
                        exact
                        path={route.path}
                        component={route.component}
                        cartItemsRequired={route.cartItemsRequired}
                        shopOpenRequired={route.shopOpenRequired}
                    />
                ))}
                <Route component={NotFound} />
            </Switch>
        </Suspense>
    );
};

export default Routes;
