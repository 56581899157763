import { get, isEmpty } from "lodash-es";

import React from "react";
import { getLanguageCode } from "./langUtil";

export const displayError = (enqueueSnackbar, error) => {
    if (error.response) {
        let languageCode = getLanguageCode();
        let trace_id = error.response.data.traceId ? error.response.data.traceId : "";
        let status_code = error.response.status ? error.response.status : "";
        let error_message = "";
        if (error.response.data.errors) {
            if (!isEmpty(error.response.data.errors[0].errorMessage[languageCode])) {
                error_message = error.response.data.errors[0].errorMessage[languageCode];
            } else {
                error_message = error.response.data.errors[0].errorMessage.en;
            }
        }
        if (!error_message) {
            error_message = get(
                error,
                "response.data.error",
                get(error, "response.data.fault.message", "")
            );
        }
        if (error_message) {
            displayMessage(
                enqueueSnackbar,
                <div>
                    {status_code && <div>{`Status code: ${status_code}`}</div>}
                    {trace_id && <div>{`TraceId: ${trace_id}`}</div>}
                    <div>{`Message : ${error_message}`}</div>
                </div>,
                "error"
            );
        }
    } else {
        displayMessage(enqueueSnackbar, <div>{`Message: ${error.message}`}</div>, "error");
    }
};

export const displayMessage = (enqueueSnackbar, message, variant) => {
    enqueueSnackbar(message, variant);
};
