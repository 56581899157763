import {
    getAuthToken,
    getOrgNumber,
    getSelectedStore,
    getStoreDomain,
    removeAuthToken,
    setAuthToken,
} from "@utils/localStorageUtil";
import { createDeviceScope, getEncodedString } from "@utils/authUtil";

import { WEB_ORDER_URL } from "@config/apiConfig";
import axios from "axios";

export const initAxios = () => {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_API_URL; // sets the base URL for all axios API call
    axios.defaults.timeout = 60000; // sets the request time-out to 1 min

    //Add a response interceptor to refresh the auth token
    axios.interceptors.response.use(
        (response) => {
            return response;
        },
        async (error) => {
            const originalRequest = error.config;
            if (
                error.response &&
                error.response.status === 401 &&
                !originalRequest._retry &&
                originalRequest.url.replace(originalRequest.baseURL, "") !== "/token"
            ) {
                removeAuthToken();
                originalRequest._retry = true;
                const headers = getEncodedString();
                const data = `grant_type=client_credentials&scope=${encodeURIComponent(
                    createDeviceScope()
                )}`;
                return axios.post("/token", data, { headers }).then((res) => {
                    if (res.status === 200) {
                        setAuthToken(res.data.access_token);
                        return axios(originalRequest);
                    }
                });
            }
            return Promise.reject(error);
        }
    );

    // Add a request interceptor to include the auth token in every api request
    axios.interceptors.request.use(
        (config) => {
            const token = getAuthToken();
            const orgNumber = getOrgNumber();
            const storeDomain = getStoreDomain();
            const storeId = getSelectedStore();
            if (token) {
                config.headers["Authorization"] = "Bearer " + token;
            }

            if (orgNumber && config.url.includes(WEB_ORDER_URL)) {
                config.headers["storeDomain"] = storeDomain;
                config.headers["storeId"] = storeId;
            }
            return config;
        },
        (error) => {
            Promise.reject(error);
        }
    );
};
