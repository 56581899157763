import axios from "axios";
import { displayError } from "./toastUtil";
import { get } from "lodash-es";

export const getEndpoint = async (
    endpoint,
    saveFunction,
    enqueueSnackbar,
    catchFunction,
    dataPath,
    config
) => {
    const request = axios.get(endpoint, config);
    return request
        .then((response) => {
            const data = dataPath ? get(response, dataPath) : get(response, "data.response");
            saveFunction(data);
        })
        .catch((error) => {
            if (catchFunction && typeof catchFunction === "function") {
                catchFunction(error);
            } else {
                displayError(enqueueSnackbar, error);
            }
        });
};

export const getAllEndpoints = async (getArray, enqueueSnackbar, globalConfig) => {
    const requests = [];

    for (const [url, saveFunc, catchFunc, dataPath, config] of getArray) {
        requests.push(
            getEndpoint(url, saveFunc, enqueueSnackbar, catchFunc, dataPath, config || globalConfig)
        );
    }

    return Promise.all(requests).catch((error) => {
        const errors = get(error, "response.data.errors");
        if (errors) {
            const error_message = get(errors[0], "errorMessage.en");
            const error_status = get(errors[0], "errorCode");
            if (error_message && error_status !== 404) displayError(enqueueSnackbar, error);
        }
    });
};

export const getEndpointPromise = async (endpoint, config) => {
    return axios
        .get(endpoint, config)
        .then((response) => {
            const responseData = get(response, "data.response");
            return responseData || response;
        })
        .catch((error) => Promise.reject(error));
};

export async function insertEndpointPromise(endpoint, data, dataPath, config) {
    const request = axios.post(endpoint, data, config);
    return request
        .then((response) => {
            const responseData = dataPath
                ? get(response, dataPath)
                : get(response, "data.response");
            return responseData || response;
        })
        .catch((error) => Promise.reject(error));
}

export const updateEndpointPromise = async (endpoint, data, config) => {
    return axios.patch(endpoint, data, config).catch((error) => Promise.reject(error));
};

export const getURLEncodedParams = (queryParams) => {
    const urlParams = new URLSearchParams();
    for (const [key, value] of Object.entries(queryParams)) {
        if (value) {
            urlParams.append(key, value);
        }
    }
    return urlParams;
};
