import { getDeviceScope, setDeviceScope, setAuthToken, removeAuthToken } from "./localStorageUtil";
import axios from "axios";

export const createAuthToken = async () => {
    removeAuthToken();
    const headers = getEncodedString();
    const data = `grant_type=client_credentials&scope=${encodeURIComponent(createDeviceScope())}`;
    return axios.post("/token", data, { headers }).then((res) => {
        if (res.status === 200) {
            setAuthToken(res.data.access_token);
            return res.data.access_token;
        }
    });
};

export const createDeviceScope = () => {
    const deviceScope = getDeviceScope();
    if (deviceScope) {
        return deviceScope;
    } else {
        let result = "";
        let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
        for (let i = 0; i < 8; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        result = "device_WO" + result;
        setDeviceScope(result);
        return result;
    }
};

export const getEncodedString = () => {
    return {
        Authorization: `Basic ${process.env.REACT_APP_CONSUMER_TOKEN}`,
    };
};
