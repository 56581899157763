import { createSlice } from "@reduxjs/toolkit";

export const deliveryInfoSlice = createSlice({
    name: "deliveryInfo",
    initialState: {
        deliveryInfoSubmitted: false,
        deliveryAddressValid: false,
    },
    reducers: {
        setDeliveryInfoSubmitted: (state, action) => {
            state.deliveryInfoSubmitted = action.payload;
        },
        setDeliveryAddressValid: (state, action) => {
            state.deliveryAddressValid = action.payload;
        },
    },
});

export const { setDeliveryInfoSubmitted, setDeliveryAddressValid } = deliveryInfoSlice.actions;

export default deliveryInfoSlice.reducer;
