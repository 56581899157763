import Cookies from "universal-cookie";
import { get } from "lodash-es";
import _mapValues from "lodash/mapValues";
import { setLanguage } from "src/store/languageSlice";
import { axiosPhrase } from "@config/phraseConfig";

export const languageCodes = {
    SE: "sv",
    EN: "en",
};

const PHRASE_LOCALE_ID_MAPPING = {
    en: process.env.REACT_APP_PHRASE_LOCALE_ID_EN,
    sv: process.env.REACT_APP_PHRASE_LOCALE_ID_SV,
};

export const getLocale = () => {
    const cookies = new Cookies();
    return cookies.get("lang") ? cookies.get("lang") : "SE";
};

export const setLocale = (value) => {
    const cookies = new Cookies();
    cookies.set("lang", value, { path: "/" });
    window.location.reload();
};

export const getLanguageCode = () => {
    const language = getLocale();
    return get(languageCodes, language, "sv");
};

const setLanguageAction = (translation) => setLanguage({ locale: getLocale(), translation });

export function fetchLanguageTranslation() {
    const languageKey = getLanguageCode();
    const localeId = PHRASE_LOCALE_ID_MAPPING[languageKey];
    const localTranslation = require("./translation.json");

    return (dispatch) =>
        axiosPhrase
            .get(`/locales/${localeId}/download?file_format=json`)
            .then((response) => {
                const phraseTranslation = _mapValues(response.data, (tr) => tr.message);
                // use local translation and override it with phrase
                const translation = { ...localTranslation[languageKey], ...phraseTranslation };
                dispatch(setLanguageAction(translation));
            })
            .catch(() => {
                dispatch(setLanguageAction(localTranslation));
            });
}
