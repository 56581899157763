import { AppBar, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { AppContext } from "@contextProviders/AppContextProvider";
import { get } from "lodash";
import { ORDER_TYPES, WEB_ORDER_DELIVERY_TYPES } from "@utils/commonUtil";
import { getTranslation } from "src/store/languageSlice";
import { ORDER_TYPE, SELECTED_TABLE } from "@utils/localStorageUtil";
import { deleteFromStorage, writeStorage } from "@rehooks/local-storage";

const useStyles = makeStyles(() => ({
    grow: {
        flexGrow: 1,
    },
    banner: {
        paddingLeft: 10,
        display: "block",
        backgroundColor: "#3E3E3E",
    },
    caption: {
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "19px",
        textTransform: "uppercase",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
    },
    changeOrderType: {
        cursor: "pointer",
        paddingRight: 10,
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "19px",
        textTransform: "uppercase",
        textDecoration: "underline",
        whiteSpace: "nowrap",
        justifySelf: "flex-end",
    },
    container: {
        display: "flex",
        paddingTop: 3,
        paddingBottom: 3,
        justifyContent: "space-between",
        flexWrap: "wrap",
    },
}));

const OrderTypeBar = () => {
    const classes = useStyles();
    const t = useSelector(getTranslation);
    const { store, orderType, selectedTable } = useContext(AppContext);
    const storeOpen = get(store, "webStoreStatus") === "open" ? true : false;
    const webOrderDeliveryType = get(store, "webOrderDeliveryType");
    const orderTypeSet =
        orderType === ORDER_TYPES.takeAway || (orderType === ORDER_TYPES.eatIn && selectedTable);
    const excludedPages = ["checkout", "payment-type", "payment"];
    const showOrderTypeBar =
        orderTypeSet && !excludedPages.some((page) => location.pathname.includes(page));
    const userCanChangeOrderType =
        orderTypeSet && webOrderDeliveryType === WEB_ORDER_DELIVERY_TYPES.takeAwayAndEatIn;

    const resetOrderType = () => {
        if (!userCanChangeOrderType) {
            return;
        }
        if (orderType === ORDER_TYPES.takeAway) {
            deleteFromStorage(SELECTED_TABLE);
            writeStorage(ORDER_TYPE, ORDER_TYPES.eatIn);
        }
        if (orderType === ORDER_TYPES.eatIn) {
            deleteFromStorage(SELECTED_TABLE);
            writeStorage(ORDER_TYPE, ORDER_TYPES.takeAway);
        }
    };

    if (showOrderTypeBar) {
        return (
            <div className={classes.grow}>
                <AppBar position="static" elevation={0} className={classes.banner}>
                    <div className={classes.container}>
                        <Typography color="inherit" className={classes.caption}>
                            {storeOpen &&
                                orderType &&
                                (orderType === ORDER_TYPES.takeAway
                                    ? t.take_away
                                    : `${t.eat_in}: ${get(selectedTable, "label", "")}`)}
                        </Typography>
                        {userCanChangeOrderType && (
                            <Typography
                                color="inherit"
                                className={classes.changeOrderType}
                                onClick={resetOrderType}
                            >
                                {orderType === ORDER_TYPES.takeAway
                                    ? t.order_type_change_to_eat_in
                                    : t.order_type_change_to_take_away}
                            </Typography>
                        )}
                    </div>
                </AppBar>
            </div>
        );
    } else {
        return <></>;
    }
};

export default OrderTypeBar;
