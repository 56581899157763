import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";

const WOButton = (props) => {
    const {
        children,
        handleClick,
        disabled,
        color = "primary",
        variant = "contained",
        size = "medium",
        style,
    } = props;

    return (
        <Button
            size={size}
            color={color}
            variant={variant}
            onClick={handleClick}
            disabled={disabled}
            style={style}
        >
            {children}
        </Button>
    );
};

WOButton.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    handleClick: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    color: PropTypes.oneOf(["primary", "secondary", "default"]),
    variant: PropTypes.oneOf(["contained", "outlined"]),
    size: PropTypes.oneOf(["small", "medium", "large"]),
    style: PropTypes.object,
};

export default WOButton;
