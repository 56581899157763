export const ACCESS_TOKEN_KEY = "auth.access_token";
export const ORG_NUMBER_KEY = "org_number";
export const STORE_DOMAIN = "store_domain";
export const IS_COOKIE_ACCEPT = "is_cookie_accept";
export const SELECTED_STORE = "selectedStore";
export const ORDER_TYPE = "orderType";
export const SELECTED_TABLE = "selectedTable";
export const SELECTED_TABLE_ID = "selectedTableId";
export const DEVICE_SCOPE = "device_scope";

const storage = {
    get: (key) => {
        return window.localStorage.getItem(key);
    },
    set: (key, val) => {
        return window.localStorage.setItem(key, val);
    },
    remove: (key) => {
        return window.localStorage.removeItem(key);
    },
};

const setAuthToken = (token) => {
    storage.set(ACCESS_TOKEN_KEY, token);
};

const getAuthToken = () => {
    return storage.get(ACCESS_TOKEN_KEY);
};

const removeAuthToken = () => {
    storage.remove(ACCESS_TOKEN_KEY);
};

const setOrgNumber = (orgNumber) => {
    storage.set(ORG_NUMBER_KEY, orgNumber);
};

const getOrgNumber = () => {
    return storage.get(ORG_NUMBER_KEY);
};

const setStoreDomain = (storeDomain) => {
    storage.set(STORE_DOMAIN, storeDomain);
};

const getStoreDomain = () => {
    return storage.get(STORE_DOMAIN);
};

const removeOrgNumber = () => {
    storage.remove(ORG_NUMBER_KEY);
};

const clearStore = () => {
    window.localStorage.clear();
};

const setIsCookieAccept = (value) => {
    storage.set(IS_COOKIE_ACCEPT, value);
};

const getIsCookieAccept = () => {
    return storage.get(IS_COOKIE_ACCEPT);
};

const getSelectedStore = () => {
    return storage.get(SELECTED_STORE);
};

const setSelectedStore = (storeId) => {
    storage.set(SELECTED_STORE, storeId);
};

const removeSelectedStore = () => {
    storage.remove(SELECTED_STORE);
};

const getOrderType = () => {
    return storage.get(ORDER_TYPE);
};

const setOrderType = (orderType) => {
    storage.set(ORDER_TYPE, orderType);
};

const setDeviceScope = (deviceScope) => {
    storage.set(DEVICE_SCOPE, deviceScope);
};

const getDeviceScope = () => {
    return storage.get(DEVICE_SCOPE);
};

export {
    clearStore,
    getAuthToken,
    setAuthToken,
    removeAuthToken,
    setDeviceScope,
    getDeviceScope,
    setOrgNumber,
    getOrgNumber,
    removeOrgNumber,
    setIsCookieAccept,
    getIsCookieAccept,
    setStoreDomain,
    getStoreDomain,
    getSelectedStore,
    setSelectedStore,
    removeSelectedStore,
    getOrderType,
    setOrderType,
};
