export const formatCurrency = (amount, languageCode, countryCode, currencyCode) => {
    return Intl.NumberFormat(`${languageCode}-${countryCode}`, {
        currency: currencyCode,
        style: "currency",
    }).format(amount);
};

export const getDisplaySign = (amount) => {
    let sign = "";
    if (amount > 0) {
        sign = "+";
    } else if (amount < 0) {
        sign = "-";
    }
    return sign;
};
