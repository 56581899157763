import { deleteFromStorage, useLocalStorage, writeStorage } from "@rehooks/local-storage";
import React, { createContext, useEffect, useState } from "react";

import defaultTheme from "@config/theme";
import {
    CART_STATE,
    CART_STATES,
    getOrderTypeFromWebOrderDeliveryType,
    setPageTitle,
    WEB_ORDER_DELIVERY_TYPES,
} from "@utils/commonUtil";
import {
    getOrderType,
    ORDER_TYPE,
    SELECTED_TABLE,
    setOrgNumber,
    setStoreDomain,
} from "@utils/localStorageUtil";
import { removeOrderInfo } from "@utils/sessionStorageUtil";
import CryptoJS from "crypto-js";
import { get } from "lodash";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import { getTranslation, isLanguageSet } from "src/store/languageSlice";
import { fetchLanguageTranslation } from "@utils/langUtil";
import { v4 as uuidV4 } from "uuid";

// Create Context Object
export const AppContext = createContext();

const CART_KEY = "cart";
export const PAY_PURCHASE_RESPONSE = "payPurchaseResponse";
export const PAYMENT_TYPE = {
    card: "card",
    swish: "swish",
};
// Create a provider for components to consume and subscribe to changes
export const AppContextProvider = (props) => {
    const [theme, setTheme] = useState(defaultTheme);
    const [loading, setLoading] = useState(false);
    const [organization, setOrganization] = useState(null);
    const [storeList, setStoreList] = useState([]);
    const [subDomain, setSubDomain] = useState("");
    const [store, setStore] = useState(null);
    const [storeOpen, setStoreOpen] = useState(true);
    const [encryptedCart] = useLocalStorage(CART_KEY);
    const [selectedGroupId, setSelectedGroupId] = useState(null);
    const [orderType] = useLocalStorage(ORDER_TYPE);
    const [selectedTable] = useLocalStorage(SELECTED_TABLE);
    const dispatch = useDispatch();
    const t = useSelector(getTranslation);
    const isLanguageSetState = useSelector(isLanguageSet);

    const getDecryptedCart = () => {
        let decryptedCart;
        if (encryptedCart && organization) {
            try {
                const bytes = CryptoJS.AES.decrypt(encryptedCart, organization.id);
                decryptedCart = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            } catch (error) {
                removeCart();
            }
        }
        return decryptedCart || { products: [], id: uuidV4() };
    };

    const cart = getDecryptedCart();

    useEffect(() => {
        if (organization) {
            setOrgNumber(organization.organizationNumber);
        }
    }, [organization]);

    useEffect(() => {
        if (subDomain) {
            setStoreDomain(subDomain);
        }
    }, [subDomain]);

    useEffect(() => {
        if (!isLanguageSetState) {
            dispatch(fetchLanguageTranslation());
        }
    }, []);

    useEffect(() => {
        if (!isLanguageSetState) return;
        const webStoreStatus = get(store, "webStoreStatus");
        if (store && webStoreStatus !== storeOpen) {
            const pageTitle = `${get(store, "name")}, ${get(store, "addressObject.street").replace(
                /\s\d+$/,
                ""
            )},
			${get(store, "addressObject.city")} - ${t.order_now}!`;
            setPageTitle(pageTitle);
            setTheme({
                ...theme,
                palette: {
                    ...theme.palette,
                    mainBanner: {
                        ...theme.palette.mainBanner,
                        ...(store.mainBannerFontColor && { color: store.mainBannerFontColor }),
                        ...(store.mainBannerFont && { fontFamily: store.mainBannerFont }),
                        ...(store.mainBannerBackgroundColor && {
                            backGroundColor: store.mainBannerBackgroundColor,
                        }),
                    },
                    categoryButton: {
                        ...theme.typography.categoryButton,
                        boxShadow: get(store, "showCategoryButtonShadow")
                            ? "0px 0px 2px rgb(0 0 0 / 30%)"
                            : "none",
                        border: get(store, "showCategoryButtonShadow")
                            ? "none"
                            : "1px solid rgb(0 0 0 / 30%)",
                    },
                },
                typography: {
                    ...theme.typography,
                    ...(get(store, "bodyFont") && {
                        fontFamily: get(store, "bodyFont", [theme.typography.fontFamily].join(",")),
                        body1: {
                            ...theme.typography.body1,
                            fontFamily: get(
                                store,
                                "bodyFont",
                                [theme.typography.fontFamily].join(",")
                            ),
                        },
                        body2: {
                            ...theme.typography.body2,
                            fontFamily: get(
                                store,
                                "bodyFont",
                                [theme.typography.fontFamily].join(",")
                            ),
                        },
                        button: {
                            ...theme.typography.button,
                            fontFamily: get(
                                store,
                                "bodyFont",
                                [theme.typography.fontFamily].join(",")
                            ),
                            ...(get(store, "buttonTextColor") && {
                                color: get(store, "buttonTextColor"),
                            }),
                        },
                        caption: {
                            ...theme.typography.caption,
                            fontFamily: get(
                                store,
                                "bodyFont",
                                [theme.typography.fontFamily].join(",")
                            ),
                        },
                    }),
                },
                overrides: {
                    ...theme.overrides,
                    MuiButton: {
                        containedSecondary: {
                            ...(get(store, "buttonTextColor") && {
                                color: get(store, "buttonTextColor"),
                            }),
                            ...(get(store, "buttonColor") && {
                                backgroundColor: get(store, "buttonColor"),
                                "&:hover": {
                                    backgroundColor: `${get(store, "buttonColor")} !important`,
                                },
                            }),
                        },
                    },
                    MuiBadge: {
                        ...theme.overrides.MuiBadge,
                        colorPrimary: {
                            ...theme.overrides.MuiBadge.colorPrimary,
                            ...(get(store, "buttonTextColor") && {
                                color: get(store, "buttonTextColor"),
                            }),
                        },
                    },
                },
            });
            const webOrderDeliveryType = get(store, "webOrderDeliveryType");
            if (
                webOrderDeliveryType === WEB_ORDER_DELIVERY_TYPES.takeAway ||
                webOrderDeliveryType === WEB_ORDER_DELIVERY_TYPES.eatIn
            ) {
                // store changed to a single delivery type
                if (getOrderType() !== webOrderDeliveryType) {
                    writeStorage(
                        ORDER_TYPE,
                        getOrderTypeFromWebOrderDeliveryType(webOrderDeliveryType)
                    );
                }
            }
            setStoreOpen(webStoreStatus);
        }
    }, [isLanguageSetState, store, storeOpen, t, theme]);

    const setSelectedProductGroup = (groupId) => {
        if (selectedGroupId === groupId) {
            setSelectedGroupId(null);
        } else {
            setSelectedGroupId(groupId);
        }
    };

    const addOrUpdateProductToCart = (cartItem) => {
        const products = cart.products;
        const existingCartProduct = products.find(
            (item) => item.cartItemId === cartItem.cartItemId
        );
        if (existingCartProduct) {
            const existingIndex = products.findIndex(
                (item) => item.cartItemId === cartItem.cartItemId
            );
            products.splice(existingIndex, 1, cartItem);
        } else {
            products.push(cartItem);
        }
        addPropertyToCart(CART_STATE, CART_STATES.updated);
        addPropertyToCart("products", products);
    };

    const addPropertyToCart = (key, value) => {
        if (cart[PAY_PURCHASE_RESPONSE]) {
            delete cart[PAY_PURCHASE_RESPONSE];
        }
        cart[key] = value;
        encryptCartAndWriteToLocalStorage();
    };

    const removePropertyFromCart = (key) => {
        if (cart[key]) {
            delete cart[key];
        }
        encryptCartAndWriteToLocalStorage();
    };

    const removeCart = () => {
        removeOrderInfo();
        deleteFromStorage(CART_KEY);
        deleteFromStorage(SELECTED_TABLE);
        if (
            !store ||
            get(store, "webOrderDeliveryType") === WEB_ORDER_DELIVERY_TYPES.takeAwayAndEatIn
        ) {
            deleteFromStorage(ORDER_TYPE);
        }
    };

    const clearCartProducts = () => {
        addPropertyToCart("products", []);
    };

    const removeProductFromCart = (cartItemId) => {
        let products = cart.products;
        products = products.filter((item) => item.cartItemId !== cartItemId);
        addPropertyToCart(CART_STATE, CART_STATES.updated);
        addPropertyToCart("products", products);
    };

    const encryptCartAndWriteToLocalStorage = () => {
        const encryptedCartValues = CryptoJS.AES.encrypt(
            JSON.stringify(cart),
            organization.id
        ).toString();
        writeStorage(CART_KEY, encryptedCartValues);
    };

    return (
        <AppContext.Provider
            value={{
                theme,
                setTheme,
                loading,
                setLoading,
                organization,
                setOrganization,
                store,
                setStore,
                cart,
                setSubDomain,
                subDomain,
                setStoreOpen,
                storeOpen,
                addOrUpdateProductToCart,
                addPropertyToCart,
                removeProductFromCart,
                clearCartProducts,
                removeCart,
                removePropertyFromCart,
                setStoreList,
                storeList,
                setSelectedProductGroup,
                selectedGroupId,
                orderType,
                selectedTable,
            }}
        >
            {props.children}
        </AppContext.Provider>
    );
};

AppContextProvider.propTypes = {
    children: PropTypes.node.isRequired,
};
