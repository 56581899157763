import { AppBar, Typography, useMediaQuery } from "@material-ui/core";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { AppContext } from "@contextProviders/AppContextProvider";
import { get } from "lodash";
import clsx from "clsx";
import theme from "@config/theme";
import { getTranslation } from "src/store/languageSlice";

const useStyles = makeStyles(() => ({
    grow: {
        flexGrow: 1,
    },
    banner: {
        paddingLeft: 10,
        height: 30,
        display: "block",
        backgroundColor: "#EBEBEB",
    },
    icon: {
        maxWidth: 13,
        maxHeight: 13,
        float: "left",
        transform: "translateY(20%)",
    },
    caption: {
        paddingLeft: 20,
        fontSize: 12,
        fontWeight: "bold",
        lineHeight: "19px",
    },
    container: {
        paddingRight: 10,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    },
    cookingTime: {
        fontWeight: 300,
        float: "right",
    },
    mobileView: {
        fontSize: 10,
    },
    cookingCaption: {
        display: "inline",
    },
    orderStatus: {
        paddingLeft: 10,
        float: "left",
    },
    cookingTimeValue: {
        fontWeight: "bold",
    },
}));

const TopBar = () => {
    const classes = useStyles();
    const t = useSelector(getTranslation);
    const { store } = useContext(AppContext);
    const storeOpen = get(store, "webStoreStatus") === "open" ? true : false;
    const smallViewPort = useMediaQuery(theme.breakpoints.down(399));
    const miniViewPort = useMediaQuery(theme.breakpoints.down(358));

    return (
        <div className={classes.grow}>
            <AppBar position="static" elevation={0} className={classes.banner}>
                <div className={classes.container}>
                    {storeOpen ? (
                        <>
                            <img
                                src={"/assets/images/store/open.png"}
                                alt={t.open_for_orders}
                                className={classes.icon}
                            />
                            <Typography
                                color="primary"
                                className={
                                    smallViewPort
                                        ? clsx(
                                              classes.caption,
                                              classes.cookingCaption,
                                              classes.mobileView
                                          )
                                        : clsx(classes.caption, classes.cookingCaption)
                                }
                            >
                                <span className={classes.orderStatus}> {t.open_for_orders}</span>
                                {!miniViewPort &&
                                    get(store, "selectedAveragePreparationTime", 0) > 0 && (
                                        <span className={classes.cookingTime}>
                                            {t.cooking_time_approximately}
                                            <span className={classes.cookingTimeValue}>
                                                {get(store, "selectedAveragePreparationTime")
                                                    ? ` ${get(
                                                          store,
                                                          "selectedAveragePreparationTime"
                                                      )} `
                                                    : " "}
                                                {t.min}
                                            </span>
                                        </span>
                                    )}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <img
                                src={"/assets/images/store/closed.png"}
                                alt={t.close_for_orders}
                                className={classes.icon}
                            />
                            <Typography color="primary" className={classes.caption}>
                                {t.close_for_orders}
                            </Typography>
                        </>
                    )}
                </div>
            </AppBar>
        </div>
    );
};

export default TopBar;
