import React, { useContext, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import { makeStyles, Typography, DialogContent, DialogTitle } from "@material-ui/core";
import theme from "@config/theme";
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { SELECTED_TABLE, SELECTED_TABLE_ID } from "@utils/localStorageUtil";
import { AppContext } from "@contextProviders/AppContextProvider";
import { WEB_ORDER_URL } from "@config/apiConfig";
import { getEndpoint } from "@utils/apiUtil";
import { useSnackbar } from "notistack";
import WOGroupSelector from "@shared/FormFields/WOGroupSelector";
import WOButtonGroup from "@shared/FormFields/WOButtonGroup";
import WOButton from "@shared/FormFields/WOButton";
import useLocalStorage, { deleteFromStorage, writeStorage } from "@rehooks/local-storage";
import { getTranslation } from "src/store/languageSlice";
import WOSelect from "@shared/FormFields/WOSelect";
import { SELECTOR_TYPES } from "@utils/commonUtil";

const useStyles = makeStyles({
    banner: {
        bottom: 0,
        position: "fixed",
        width: "100%",
    },
    dialogContent: {
        minWidth: 500,
        [theme.breakpoints.down("xs")]: {
            minWidth: "100vw",
            paddingBottom: 25,
        },
    },
    caption: {
        fontSize: 20,
        fontWeight: 600,
    },
    secondaryCaption: {
        fontSize: 18,
        fontWeight: 600,
        margin: 10,
    },
    content: {
        display: "grid",
    },
    buttonContainer: {
        margin: "20px 0px",
        display: "grid",
    },
});

const TableSelector = () => {
    const classes = useStyles();
    const [tableSelector, setTableSelector] = useState(true);
    const [selectedTable, setSelectedTable] = useState("");
    const [selectorType, setSelectorType] = useState(SELECTOR_TYPES.groupSelector);
    const [commonLayout, setCommonLayout] = useState({});
    const t = useSelector(getTranslation);
    const { store } = useContext(AppContext);
    const [layoutInfo, setLayoutInfo] = useState([]);
    const [selectedTableId] = useLocalStorage(SELECTED_TABLE_ID);
    const tableSelectorId = "web-order-table-selector";
    const { enqueueSnackbar } = useSnackbar();

    const handleTableSelection = () => {
        let tableSelectorElement = document.getElementById(tableSelectorId);
        let selectedTableInfo = {};
        if (selectorType === SELECTOR_TYPES.groupSelector) {
            let option = get(
                tableSelectorElement,
                `options[${get(tableSelectorElement, "selectedIndex")}]`
            );
            let optgroup = get(option, "parentNode");
            selectedTableInfo = {
                label: `${get(optgroup, "label")}/${get(option, "label")}`,
                value: get(tableSelectorElement, "value"),
                layoutId: get(optgroup, "id"),
            };
        } else {
            let option = get(
                tableSelectorElement,
                `options[${get(tableSelectorElement, "selectedIndex")}]`
            );
            selectedTableInfo = {
                label: `${get(commonLayout, "name")}/${get(option, "label")}`,
                value: get(tableSelectorElement, "value"),
                layoutId: get(commonLayout, "id"),
            };
        }
        writeStorage(SELECTED_TABLE, selectedTableInfo);
        setTableSelector(false);
    };

    useEffect(() => {
        getLayoutInfo();
    }, []);

    const getLayoutInfo = async () => {
        await getEndpoint(
            `${WEB_ORDER_URL}/layout?layoutType=webOrder&storeId=${get(store, "storeId")}`,
            processLayoutInfo,
            enqueueSnackbar
        );
    };

    const processLayoutInfo = (layoutData) => {
        let layoutList = [];
        let tableList = [];
        layoutData.forEach((layout) => {
            tableList = [];
            get(layout, "body.resources", []).forEach((layoutChild) => {
                tableList.push({
                    label: get(layoutChild, "name"),
                    value: get(layoutChild, "id"),
                });
            });
            layoutList.push({
                groupId: get(layout, "layoutId"),
                groupName: get(layout, "name"),
                options: tableList,
            });
        });
        //if there is only single section
        if (layoutList.length === 1) {
            tableList = [];
            let layoutId = "";
            let layoutName = "";
            setSelectorType(SELECTOR_TYPES.materialSelector);
            layoutData.forEach((layout) => {
                tableList = [];
                get(layout, "body.resources", []).forEach((layoutChild) => {
                    tableList.push({
                        label: `${get(layoutChild, "name")}`,
                        value: `${get(layoutChild, "id")}`,
                    });
                });
                layoutId = get(layout, "layoutId");
                layoutName = get(layout, "name");
            });
            setCommonLayout({ id: layoutId, name: layoutName });
            setLayoutInfo(tableList);
        } else {
            setLayoutInfo(layoutList);
        }

        if (selectedTableId) {
            deleteFromStorage(SELECTED_TABLE_ID);
            setSelectedTable(selectedTableId);
        } else {
            if (selectorType === SELECTOR_TYPES.groupSelector) {
                setSelectedTable(get(layoutList, "[0].options.[0].value", ""));
            } else {
                setSelectedTable(get(layoutList, "[0].value", ""));
            }
        }
    };

    return (
        <div className={classes.banner}>
            <Dialog
                open={tableSelector}
                PaperProps={{
                    style: {
                        height: "auto",
                        maxHeight: 350,
                        minHeight: 300,
                        position: "fixed",
                        margin: 0,
                        padding: 20,
                    },
                }}
                disableBackdropClick
            >
                <DialogTitle>
                    <Typography align="center" className={classes.caption}>
                        {t.welcome_to}
                        <br /> {get(store, "name")}!
                    </Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <div className={classes.content}>
                        <Typography align="center" className={classes.secondaryCaption}>
                            {t.confirm_place_to_eat}
                        </Typography>
                        {selectorType === SELECTOR_TYPES.groupSelector ? (
                            <WOGroupSelector
                                name={tableSelectorId}
                                value={selectedTable}
                                options={layoutInfo}
                                style={{
                                    height: 45,
                                    borderRadius: 2,
                                    width: "70%",
                                    margin: "auto",
                                }}
                                handleChange={(event) => setSelectedTable(event.target.value)}
                            />
                        ) : (
                            <WOSelect
                                name={tableSelectorId}
                                value={selectedTable}
                                options={layoutInfo}
                                style={{
                                    height: 45,
                                    borderRadius: 2,
                                    width: "70%",
                                    margin: "auto",
                                }}
                                handleChange={(event) => setSelectedTable(event.target.value)}
                            />
                        )}
                    </div>
                    <div className={classes.buttonContainer}>
                        <WOButtonGroup>
                            <WOButton
                                handleClick={handleTableSelection}
                                size={"large"}
                                variant={"contained"}
                                style={{
                                    width: "70%",
                                    height: 49,
                                    margin: "auto",
                                }}
                                disabled={!selectedTable}
                            >
                                {t.start_order}
                            </WOButton>
                        </WOButtonGroup>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default TableSelector;
