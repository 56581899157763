import { AppBar, IconButton, Toolbar, Typography } from "@material-ui/core";
import React, { useContext, useState } from "react";

import { AppContext } from "@contextProviders/AppContextProvider";
import Info from "@material-ui/icons/InfoOutlined";
import { makeStyles } from "@material-ui/core/styles";
import StoreInfo from "@shared/StoreInfo";
import { Link, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import SideMenu from "@shared/SideMenu";

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
        height: 64,
    },
    banner: {
        backgroundColor: theme.palette.mainBanner.backGroundColor,
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
        fontFamily: theme.palette.mainBanner.fontFamily,
        color: theme.palette.mainBanner.color,
        textDecoration: "none",
    },
    sectionDesktop: {
        display: "none",
        [theme.breakpoints.up("sm")]: {
            display: "flex",
        },
    },
    sectionMobile: {
        display: "flex",
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    toolBar: {
        paddingLeft: 0,
    },
}));

const Header = () => {
    const classes = useStyles();
    const { store } = useContext(AppContext);
    const [showStoreInfo, setShowStoreInfo] = useState(false);
    const [showSideMenu, setShowSideMenu] = useState(false);
    const location = useLocation();
    const disableShopLink =
        location.pathname.includes("terms-and-conditions") ||
        location.pathname.includes("privacy-policy");

    const handleSideMenuClose = () => {
        setShowSideMenu(false);
    };

    return (
        <div className={classes.grow}>
            <AppBar position="static" elevation={0} className={classes.banner}>
                <Toolbar color="primary" className={classes.toolBar}>
                    <IconButton
                        aria-label="store info"
                        color="inherit"
                        onClick={() => setShowSideMenu(!showSideMenu)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <SideMenu open={showSideMenu} handleClose={handleSideMenuClose} />
                    <Typography noWrap>
                        {disableShopLink ? (
                            <div className={classes.title}>{store.name}</div>
                        ) : (
                            <Link className={classes.title} to={{ pathname: "/" }}>
                                {store.name}
                            </Link>
                        )}
                    </Typography>
                    <div className={classes.grow} />
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            aria-label="store info"
                            color="inherit"
                            onClick={() => setShowStoreInfo(!showStoreInfo)}
                        >
                            <Info />
                        </IconButton>
                        <StoreInfo open={showStoreInfo} handleClose={setShowStoreInfo} />
                    </div>
                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="store info"
                            color="inherit"
                            onClick={() => setShowStoreInfo(!showStoreInfo)}
                        >
                            <Info />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default Header;
