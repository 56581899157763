import React, { useContext, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import {
    makeStyles,
    Typography,
    DialogContent,
    DialogTitle,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    Grid,
} from "@material-ui/core";
import theme from "@config/theme";
import { useSelector } from "react-redux";
import { AppContext } from "@contextProviders/AppContextProvider";
import { get } from "lodash-es";
import moment from "moment-timezone";
import { writeStorage } from "@rehooks/local-storage";
import { SELECTED_STORE } from "@utils/localStorageUtil";
import { getTranslation } from "src/store/languageSlice";
import LanguageSelect from "@shared/LanguageSelect";

const useStyles = makeStyles({
    banner: {
        bottom: 0,
        position: "fixed",
        width: "100%",
    },
    dialogContent: {
        minWidth: 500,
        [theme.breakpoints.down("xs")]: {
            minWidth: "100vw",
            paddingBottom: 25,
        },
    },
    listItemNameContainer: {
        display: "flex",
        alignItems: "center",
        width: "calc(100% - 10px)",
    },
    listItemPrimaryText: {
        fontSize: 16,
        fontWeight: 600,
        color: theme.palette.secondary.main,
    },
    listItemSecondaryText: {
        fontSize: 14,
        fontWeight: 500,
        color: "#000",
    },
    listItemSecondaryActionRoot: {
        bottom: -8,
        fontSize: 14,
        fontWeight: 600,
    },
    listItem: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
    caption: {
        margin: 15,
        fontSize: 20,
    },
    languageSelect: {
        paddingLeft: 24,
        paddingBottom: 8,
        paddingTop: 8,
    },
});

const StoreSelector = () => {
    const classes = useStyles();
    const [storeSelectorOpen, setStoreSelectorOpen] = useState(true);
    const t = useSelector(getTranslation);
    const { storeList, organization, store, removeCart } = useContext(AppContext);

    const handleStoreSelection = async (storeId) => {
        setStoreSelectorOpen(false);
        writeStorage(SELECTED_STORE, storeId);
        if (get(store, "storeId") !== storeId) {
            removeCart();
            window.location.reload();
        }
    };

    return (
        <div className={classes.banner}>
            <Dialog
                open={storeSelectorOpen}
                PaperProps={{
                    style: {
                        height: "auto",
                        maxHeight: 300,
                        position: "fixed",
                        margin: 0,
                    },
                }}
                disableBackdropClick
            >
                <DialogTitle>
                    <Typography align="center" className={classes.caption}>
                        {t.welcome_to}
                        <br /> {get(organization, "name")}!
                    </Typography>
                    <Typography align="center">{t.choose_restaurant}</Typography>
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {storeList.map((store) => (
                        <ListItem
                            key={get(store, "storeId")}
                            button
                            divider={true}
                            className={classes.listItem}
                            onClick={() => handleStoreSelection(get(store, "storeId"))}
                        >
                            <div className={classes.listItemNameContainer}>
                                <ListItemText
                                    primary={get(store, "name", "")}
                                    secondary={get(store, "address")}
                                    primaryTypographyProps={{
                                        noWrap: true,
                                        className: classes.listItemPrimaryText,
                                    }}
                                    secondaryTypographyProps={{
                                        noWrap: true,
                                        className: classes.listItemSecondaryText,
                                    }}
                                >
                                    {store.name}
                                </ListItemText>
                                <ListItemSecondaryAction
                                    classes={{ root: classes.listItemSecondaryActionRoot }}
                                >
                                    <ListItemText
                                        primary={`${get(
                                            store,
                                            `webStoreWorkHours.${moment()
                                                .format("dddd")
                                                .toLowerCase()}.openingTime`,
                                            ""
                                        )} -
											${get(store, `webStoreWorkHours.${moment().format("dddd").toLowerCase()}.closingTime`, "")}`}
                                        primaryTypographyProps={{
                                            noWrap: true,
                                            className: classes.listItemSecondaryActionRoot,
                                        }}
                                        classes={{ root: classes.listItemTextRootPrice }}
                                    />
                                </ListItemSecondaryAction>
                            </div>
                        </ListItem>
                    ))}
                </DialogContent>
                <Grid container className={classes.languageSelect}>
                    <LanguageSelect />
                </Grid>
            </Dialog>
        </div>
    );
};

export default StoreSelector;
