import React, { useContext } from "react";
import { ThemeProvider, makeStyles } from "@material-ui/styles";

import { AppContext } from "@contextProviders/AppContextProvider";
import { CssBaseline } from "@material-ui/core";
import Loader from "./shared/Loader";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "./routes";
import { SnackbarProvider } from "notistack";
import { CookiesProvider } from "react-cookie";
import { useExpireCartIfTimeElapsed } from "@utils/sessionStorageUtil";

const App = () => {
    const { theme, loading } = useContext(AppContext);

    const useStyles = makeStyles({
        success: { backgroundColor: theme.palette.success.main },
        error: { backgroundColor: theme.palette.error.main },
        warning: { backgroundColor: theme.palette.warning.main },
        info: { backgroundColor: theme.palette.info.main },
    });
    const classes = useStyles(theme);

    useExpireCartIfTimeElapsed();

    return (
        <ThemeProvider theme={theme}>
            <CookiesProvider>
                <SnackbarProvider
                    dense={true}
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    classes={{
                        variantSuccess: classes.success,
                        variantError: classes.error,
                        variantWarning: classes.warning,
                        variantInfo: classes.info,
                    }}
                >
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    {loading && <Loader />}
                    <Router>
                        <Routes />
                    </Router>
                </SnackbarProvider>
            </CookiesProvider>
        </ThemeProvider>
    );
};

export default App;
