import { configureStore } from "@reduxjs/toolkit";
import checkoutReducer from "./checkoutSlice";
import deliveryInfoReducer from "./deliveryInfoSlice";
import languageReducer from "./languageSlice";

export default configureStore({
    reducer: {
        checkout: checkoutReducer,
        deliveryInfo: deliveryInfoReducer,
        language: languageReducer,
    },
});
