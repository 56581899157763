import { FormControl, InputLabel, Select } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    select: {
        height: 49,
    },
});

const WOSelect = (props) => {
    const classes = useStyles();
    const { name, label, options, value, handleChange, style, disabled = false } = props;

    return (
        <FormControl variant="outlined" fullWidth disabled={disabled}>
            {label && <InputLabel htmlFor={name}>{label}</InputLabel>}
            <Select
                native
                value={value}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                    name: name,
                    id: name,
                }}
                style={style}
            >
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </Select>
        </FormControl>
    );
};

WOSelect.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    disabled: PropTypes.bool,
};

export default WOSelect;
