import axios from "axios";

const PHRASE_API_URL = process.env.REACT_APP_PHRASE_API_BASE_URL;
const PHRASE_AUTH_TOKEN = process.env.REACT_APP_PHRASE_AUTH_TOKEN;
const PHRASE_PROJECT_ID = process.env.REACT_APP_PHRASE_PROJECT_ID;

export const axiosPhrase = axios.create({
    baseURL: `${PHRASE_API_URL}/projects/${PHRASE_PROJECT_ID}`,
    headers: {
        Authorization: `Bearer ${PHRASE_AUTH_TOKEN}`,
    },
});
