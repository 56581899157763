import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
    Link,
    makeStyles,
    Typography,
    DialogActions,
    DialogContent,
    Button,
} from "@material-ui/core";
import theme from "@config/theme";
import { useSelector } from "react-redux";
import WOButtonGroup from "@shared/FormFields/WOButtonGroup";
import WOButton from "@shared/FormFields/WOButton";
import { setIsCookieAccept } from "@utils/localStorageUtil";
import { useHistory } from "react-router-dom";
import { getTranslation } from "src/store/languageSlice";

const useStyles = makeStyles({
    banner: {
        height: "auto",
        bottom: 0,
        position: "fixed",
        width: "100%",
    },
    dialogContent: {
        minWidth: 500,
        [theme.breakpoints.down("xs")]: {
            minWidth: "100vw",
            paddingBottom: 25,
        },
    },
    bannerText: {
        fontSize: 14,
        fontWeight: 600,
        color: theme.palette.secondary.main,
    },
    cookiePolicy: {
        fontSize: 14,
        fontWeight: 600,
        padding: 0,
        color: theme.palette.secondary.main,
        textDecoration: "underline",
        backgroundColor: "transparent",
        "&:hover": {
            background: "transparent",
        },
        verticalAlign: "inherit",
    },
});

const CookieBanner = () => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const t = useSelector(getTranslation);
    const history = useHistory();

    const handleCookieAccept = () => {
        setIsCookieAccept(true);
        setOpen(false);
    };

    return (
        <div className={classes.banner}>
            <Dialog
                open={open}
                onClose={handleCookieAccept}
                PaperProps={{
                    style: {
                        height: "auto",
                        minHeight: 177,
                        bottom: 0,
                        position: "fixed",
                        margin: 0,
                    },
                }}
                disableBackdropClick
            >
                <DialogContent className={classes.dialogContent}>
                    <Typography className={classes.bannerText}>
                        <span>{t.cookie_banner_message} </span>
                        <Button
                            component={Link}
                            className={classes.cookiePolicy}
                            onClick={() => history.push("/policy-info")}
                        >
                            {t.cookie_policy}
                        </Button>
                    </Typography>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <WOButtonGroup>
                        <WOButton
                            handleClick={handleCookieAccept}
                            size={"large"}
                            variant={"contained"}
                            style={{
                                selfAlign: "center",
                                width: 183,
                                height: 49,
                                transform: "translate(-50%, -50%)",
                                position: "relative",
                                left: "50%",
                            }}
                        >
                            {t.ok}
                        </WOButton>
                    </WOButtonGroup>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default CookieBanner;
