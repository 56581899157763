import PropTypes from "prop-types";
import React from "react";
import { get } from "lodash-es";

const WOGroupSelector = (props) => {
    const { name, options, value, style, handleChange, handleOnBlur = null } = props;

    return (
        <select
            name={name}
            id={name}
            value={value}
            onBlur={handleOnBlur}
            onChange={handleChange}
            style={style}
        >
            {options.map((group) => (
                <optgroup
                    key={get(group, "groupId")}
                    id={get(group, "groupId")}
                    label={get(group, "groupName")}
                >
                    {get(group, "options", []).map((option) => (
                        <option value={get(option, "value")} key={get(option, "value")}>
                            {get(option, "label")}
                        </option>
                    ))}
                </optgroup>
            ))}
        </select>
    );
};

WOGroupSelector.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            groupName: PropTypes.string.isRequired,
            groupId: PropTypes.string,
            options: PropTypes.arrayOf(
                PropTypes.shape({
                    label: PropTypes.string.isRequired,
                    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
                })
            ),
        })
    ).isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    handleChange: PropTypes.func.isRequired,
    style: PropTypes.object,
    handleOnBlur: PropTypes.func,
};

export default WOGroupSelector;
