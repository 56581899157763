import { AppBar, Toolbar, withWidth } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
    appBar: {
        top: "auto",
        bottom: 0,
        background: "#FFF",
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        height: 74,
    },
});

const FooterBar = (props) => {
    const classes = useStyles();
    const { position, content, additionalClassName, width } = props;

    return (
        <React.Fragment>
            <AppBar
                position={position ? position : width === "xs" ? "fixed" : "relative"}
                className={clsx(classes.appBar, additionalClassName)}
                elevation={0}
            >
                <Toolbar disableGutters>{content}</Toolbar>
            </AppBar>
        </React.Fragment>
    );
};

FooterBar.propTypes = {
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
    content: PropTypes.node.isRequired,
    position: PropTypes.oneOf(["absolute", "fixed", "relative", "static", "sticky"]),
    additionalClassName: PropTypes.string,
};

export default withWidth()(FooterBar);
