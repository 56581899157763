import { Backdrop, CircularProgress, Typography } from "@material-ui/core";

import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    loadingText: {
        color: theme.palette.text.primary,
        textAlign: "center",
    },
    loadingTextContainer: {
        marginTop: 80,
        marginLeft: -60,
    },
    backdrop: {
        zIndex: theme.zIndex.tooltip + 1,
        color: theme.palette.background.paper,
    },
}));

const Loader = (props) => {
    const { loadingText } = props;
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdrop} open={true}>
            <CircularProgress color={"primary"} />
            {loadingText && (
                <div className={classes.loadingTextContainer}>
                    <Typography variant={"h5"} className={classes.loadingText}>
                        {loadingText}
                    </Typography>
                </div>
            )}
        </Backdrop>
    );
};

Loader.propTypes = {
    loadingText: PropTypes.string,
};

export default Loader;
