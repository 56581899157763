import React, { useContext, useEffect, useState, useCallback } from "react";
import { Redirect, Route } from "react-router-dom";
import { get, isEmpty } from "lodash-es";

import { AppContext } from "@contextProviders/AppContextProvider";
import Layout from "@shared/Layout";
import Loader from "@shared/Loader";
import PropTypes from "prop-types";
import { WEB_ORDER_URL } from "@config/apiConfig";
import { getAllEndpoints } from "@utils/apiUtil";
import {
    getSelectedStore,
    removeOrgNumber,
    getAuthToken,
    removeSelectedStore,
} from "@utils/localStorageUtil";
import { createAuthToken } from "@utils/authUtil";
import { useSnackbar } from "notistack";
import { getStoreInformation } from "@utils/commonUtil";
import NotFound from "@pages/NotFound/index.js";

const SetLayout = (props) => {
    const { component: Component } = props;
    return (
        <Route
            render={(childProps) => (
                <Layout {...childProps}>
                    <Component {...childProps} />
                </Layout>
            )}
        />
    );
};

const CustomRoute = (props) => {
    const { component: Component, cartItemsRequired, shopOpenRequired, ...rest } = props;
    const {
        setOrganization,
        setStore,
        store,
        organization,
        cart,
        subDomain,
        setSubDomain,
        setStoreList,
        removeCart,
    } = useContext(AppContext);
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(getAuthToken());
    const [hasBeenFetched, setHasBeenFetched] = useState(false);
    const [notFound, setNotFound] = useState(false);
    const storeDomain = window.location.host.split(".")[0];
    const storeOpen = get(store, "webStoreStatus") === "open" ? true : false;

    const isOrganizationStoreDetailsExists = useCallback(() => {
        return !isEmpty(organization) && !isEmpty(store) && storeDomain === subDomain;
    }, [organization, store, storeDomain, subDomain]);

    useEffect(() => {
        const getToken = async () => {
            const newToken = await createAuthToken();
            setToken(newToken);
        };
        if (!token) {
            getToken();
        }
    }, [token]);

    useEffect(() => {
        const filterSingleStore = async (stores) => {
            if (stores.length === 0) {
                setNotFound(true);
                return;
            }
            setStoreList(stores);
            const selectedStore = getSelectedStore();
            const storeIds = stores.map((singleStore) => singleStore.storeId);
            if (selectedStore && storeIds.includes(selectedStore)) {
                await getStoreInformation(
                    { storeId: selectedStore },
                    setStore,
                    () => {},
                    storeDomain
                );
            } else {
                removeSelectedStore();
                removeCart();
                await getStoreInformation(get(stores, "[0]"), setStore, () => {}, storeDomain);
            }
        };

        // TODO - Replace this, once the new API has been implemented to fetch the org/store level data using subdomain
        const ORG_AND_STORE_ARRAY = [
            [`${WEB_ORDER_URL}/organization`, setOrganization],
            [`${WEB_ORDER_URL}/store/subdomain/${storeDomain}`, filterSingleStore],
        ];

        const fetchOrgAndStore = async () => {
            if (!isOrganizationStoreDetailsExists()) {
                removeOrgNumber();
                await getAllEndpoints(ORG_AND_STORE_ARRAY, enqueueSnackbar, {
                    headers: { storeDomain: storeDomain },
                });
            }
        };

        if (token) {
            setSubDomain(storeDomain);
            if (!isOrganizationStoreDetailsExists() && !hasBeenFetched) {
                fetchOrgAndStore();
                setHasBeenFetched(true);
            } else {
                setLoading(false);
            }
        }
    }, [
        enqueueSnackbar,
        isOrganizationStoreDetailsExists,
        setOrganization,
        setStore,
        setStoreList,
        setSubDomain,
        storeDomain,
        token,
        hasBeenFetched,
        removeCart,
    ]);

    useEffect(() => {
        //background job to fetch store open/close status per each 1 min
        if (store) {
            const getWebStoreInfo = setInterval(
                () => getStoreInformation(store, setStore, () => {}, storeDomain),
                60000
            );
            return () => {
                clearInterval(getWebStoreInfo);
            };
        }
    }, [setStore, store]);

    const isValidRoute = () => {
        let routeValid = true;
        if (shopOpenRequired && cartItemsRequired) {
            routeValid = !isEmpty(get(cart, "products", [])) && storeOpen;
        } else if (shopOpenRequired) {
            routeValid = storeOpen;
        } else if (cartItemsRequired) {
            routeValid = !isEmpty(get(cart, "products", []));
        }
        return routeValid;
    };

    const checkRoute = (routeProps) => {
        if (isValidRoute()) {
            return <SetLayout component={Component} />;
        } else {
            return (
                <Redirect
                    to={{
                        pathname: "/",
                        state: { from: routeProps.location },
                    }}
                />
            );
        }
    };

    let content = <Loader />;

    if (!loading && isOrganizationStoreDetailsExists()) {
        content = (
            <Route
                {...rest}
                render={(routeProps) =>
                    //check if the route is valid
                    checkRoute(routeProps)
                }
            />
        );
    }

    if (!loading && notFound) {
        content = <NotFound />;
    }

    return content;
};

CustomRoute.propTypes = {
    computedMatch: PropTypes.shape({
        params: PropTypes.shape({
            storeUniquePath: PropTypes.string,
        }).isRequired,
    }),
    component: PropTypes.elementType.isRequired,
    cartItemsRequired: PropTypes.bool,
    shopOpenRequired: PropTypes.bool,
};

SetLayout.propTypes = {
    component: PropTypes.elementType.isRequired,
};

export default CustomRoute;
