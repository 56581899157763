/* eslint-disable indent */
import { browserName, fullBrowserVersion, getUA, osName, osVersion } from "react-device-detect";
import { get, isEmpty, sum } from "lodash";
import moment from "moment-timezone";
import { v4 as uuidV4 } from "uuid";
import { WEB_ORDER_URL } from "@config/apiConfig";
import { getEndpointPromise } from "./apiUtil";
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";

export const dateTimeFormat = "YYYY-MM-DD HH:mm";
export const defaultTimeZone = "Europe/Stockholm";

export const ORDER_STATES = {
    new: "new",
    queued: "queued",
    accepted: "accepted",
    rejected: "rejected",
    prepare: "prepare",
    delivered: "delivered",
    ready: "ready",
    closed: "closed",
    canceled: "canceled",
    send: "send",
    refunded: "refunded",
    failed: "failed",
};

export const PAYMENT_METHOD = {
    swish: "Swish",
    creditCard: "CreditCard",
};
export const CART_STATES = {
    updated: "updated",
    old: "old",
};
export const CART_STATE = "cartState";

export const ORDER_TYPES = {
    eatIn: "eat-in",
    takeAway: "take-away",
};

export const WEB_ORDER_DELIVERY_TYPES = {
    eatIn: "eatIn",
    takeAway: "takeAway",
    takeAwayAndEatIn: "takeAwayAndEatIn",
};

export const getOrderTypeTranslation = (orderType, t) => {
    switch (orderType) {
        case "eat-in": {
            return t.eat_in;
        }
        case "take-away": {
            return t.take_away;
        }
    }
};

export const SELECTOR_TYPES = {
    groupSelector: "group-selector",
    materialSelector: "material-selector",
};

export const getOrderTypeFromWebOrderDeliveryType = (webOrderDeliveryType) => {
    switch (webOrderDeliveryType) {
        case WEB_ORDER_DELIVERY_TYPES.eatIn:
            return ORDER_TYPES.eatIn;
        case WEB_ORDER_DELIVERY_TYPES.takeAway:
            return ORDER_TYPES.takeAway;
        default:
            return null;
    }
};

export const getOrderStatus = (orderState, t) => {
    switch (orderState) {
        case ORDER_STATES.queued: {
            return `${t.your_order_has_now_been} ${t.queued}`;
        }
        case ORDER_STATES.accepted: {
            return `${t.your_order_has_now_been} ${t.accepted}`;
        }
        case ORDER_STATES.rejected: {
            return `${t.your_order_has_now_been} ${t.rejected}`;
        }
        case ORDER_STATES.prepare: {
            return `${t.your_order_has_now_been} ${t.prepare}`;
        }
        case ORDER_STATES.delivered: {
            return `${t.your_order_has_now_been} ${t.delivered}`;
        }
        case ORDER_STATES.ready: {
            return `${t.your_order_has_now_been} ${t.ready}`;
        }
        case ORDER_STATES.closed: {
            return `${t.your_order_has_now_been} ${t.closed}`;
        }
        case ORDER_STATES.canceled: {
            return `${t.your_order_has_now_been} ${t.canceled}`;
        }
        case ORDER_STATES.send: {
            return `${t.your_order_has_now_been} ${t.send}`;
        }
        case ORDER_STATES.refunded: {
            return `${t.your_order_has_now_been} ${t.refunded}`;
        }
        default: {
            return "";
        }
    }
};

export const getAppVersion = () => {
    return process.env.REACT_APP_VERSION;
};

export const getBuildVersion = () => {
    return process.env.REACT_APP_BUILD_VERSION;
};

export const getPlatformString = () => {
    return `${browserName} ${fullBrowserVersion}, ${osName} ${osVersion}`;
};

export const getUserAgent = () => {
    return getUA;
};

export const getXVendorDeviceId = () => {
    return process.env.REACT_APP_X_VENDOR_DEVICE_ID;
};

export const getXVDeviceName = () => {
    return process.env.REACT_APP_X_DEVICE_NAME;
};

export const getSwishNumber = (userNumber) => {
    if (isValidPhoneNumber(userNumber, "SE")) {
        return parsePhoneNumber(userNumber, "SE").number;
    }
    return "";
};

export const getStoreStatus = (webStoreWorkHours, timeZone) => {
    const format = "hh:mm";
    const storeTimeZone = timeZone || "Europe/Stockholm";
    const weekday = moment().tz(storeTimeZone).format("dddd");
    const currentTime = moment.tz(moment(), format, storeTimeZone);
    const startTime = moment.tz(
        get(webStoreWorkHours, `${weekday.toLowerCase()}.openingTime`, ""),
        format,
        storeTimeZone
    );
    const endTime = moment.tz(
        get(webStoreWorkHours, `${weekday.toLowerCase()}.closingTime`, ""),
        format,
        storeTimeZone
    );
    return currentTime.isBetween(startTime, endTime);
};

export const isLineCountExceed = (elementId, lineCount) => {
    let element = document.getElementById(elementId);
    if (element) {
        let prevLH = element.style.lineHeight;
        let factor = 1000;
        element.style.lineHeight = factor + "px";
        let height = element.getBoundingClientRect().height;
        element.style.lineHeight = prevLH;
        if (Math.floor(height / factor) >= lineCount) {
            return true;
        } else {
            return false;
        }
    } else return false;
};

export const setPageTitle = (title) => {
    document.title = title;
};

export const getCartItemsVATRateByGroup = (cartItems) => {
    const vatRates = [];
    cartItems.forEach((cartItem) => {
        if (
            !isEmpty(get(cartItem, "product.prices")) &&
            get(cartItem, "product.prices[0].taxPercentage") >= 0
        ) {
            const taxPercentage = get(cartItem, "product.prices[0].taxPercentage") / 10000000;
            const existingVATRateGroup = vatRates.find(
                (vr) => vr.taxGroupId === cartItem.product.prices[0].taxGroupId
            );
            if (existingVATRateGroup) {
                const existingVATRateGroupIndex = vatRates.findIndex(
                    (vr) => vr.taxGroupId === cartItem.product.prices[0].taxGroupId
                );
                vatRates.splice(existingVATRateGroupIndex, 1, {
                    ...existingVATRateGroup,
                    // totalVATIncludedCentAmount: sum([existingVATRateGroup.totalVATIncludedCentAmount, cartItem.totalCentAmount]),
                    vatCentAmount: Math.round(
                        sum([
                            existingVATRateGroup.vatCentAmount,
                            Math.round(
                                (cartItem.totalCentAmount / (1 + taxPercentage)) * taxPercentage
                            ),
                        ])
                    ),
                });
            } else {
                vatRates.push({
                    taxGroupId: cartItem.product.prices[0].taxGroupId,
                    taxGroupName: cartItem.product.prices[0].taxGroupName,
                    taxPercentage: cartItem.product.prices[0].taxPercentage,
                    // totalVATIncludedCentAmount: cartItem.totalCentAmount,
                    vatCentAmount: Math.round(
                        (cartItem.totalCentAmount / (1 + taxPercentage)) * taxPercentage
                    ),
                });
            }
        }
    });
    return vatRates;
};

export const getTotalWithoutTax = (cartItems) => {
    let totalWithoutTax = 0;
    cartItems.forEach((cartItem) => {
        if (!isEmpty(get(cartItem, "product.prices"))) {
            const taxPercentage = get(cartItem, "product.prices[0].taxPercentage", 0) / 10000000;
            totalWithoutTax =
                totalWithoutTax + Math.round(cartItem.totalCentAmount / (1 + taxPercentage));
        }
    });
    return totalWithoutTax;
};

export const getOrderRequestHeader = (organization, cart, store, langCode) => {
    const appVersion = getAppVersion();
    const buildVersion = getBuildVersion();
    const platformString = getPlatformString();
    const xVendorDeviceId = getXVendorDeviceId();
    const xDeviceName = getXVDeviceName();
    const deviceId = getLicenseId(store).webOrderLicenseId ?? uuidV4();

    return {
        headers: {
            deviceid: deviceId,
            "x-vendor-device-id": xVendorDeviceId,
            "x-device-name": xDeviceName,
            "store-name": store.name,
            "organization-id": get(organization, "id", ""),
            "organization-name": get(organization, "name", ""),
            "x-trace-id": get(cart, "id", ""),
            "pos-platform": platformString,
            "client-version": appVersion,
            "client-build-number": buildVersion,
            "seller-id": uuidV4(),
            "x-requested-language": langCode,
        },
    };
};
export const getItemList = (itemList, langCode, organization) => {
    const itemArray = [];
    itemList.forEach((item) => {
        let itemObj = {
            id: get(item, "cartItemId", ""),
            quantity: get(item, "quantity", 0),
            metadata: {},
            product: getProductProperties(item, langCode),
            originalPrice: getItemPrice(item, organization),
            uniqueIdentifiers: {},
            status: "delivered",
            note: get(item, "specialInstructions", ""),
            modifiers: getItemModifiers(item.modifierSets, langCode),
        };
        itemArray.push(itemObj);
    });
    return itemArray;
};

export const getItemModifiers = (itemModifierSets, langCode) => {
    let modifierSet = [];
    itemModifierSets.forEach((modifier) => {
        modifier.items.map((item) => {
            modifierSet.push({
                quantity: get(item, "quantity", 0),
                centAmount: get(item, "price.centAmount", 0),
                name: get(item, "name", {}),
                type: "modifier",
                groupName: get(modifier, `name[${langCode}]`, get(modifier, "name.sv")),
                modifierSetId: get(modifier, "modifierId"),
                modifierItemId: get(item, "modifierItemId"),
            });
        });
    });
    return modifierSet;
};

const getItemPrice = (item, organization) => {
    return {
        currencyCode: get(organization, "currencyCode", "SEK"),
        excludingTaxCentAmount: get(item, "product.prices[0].centAmountExcludingVAT", ""),
        taxCentAmount: get(item, "product.prices[0].centVATAmount", ""),
        taxPercentage: get(item, "product.prices[0].taxPercentage", ""),
        discountCentAmount: 0,
        discountPercentage: 0,
        taxes: [
            {
                groupDisplayName: get(item, "product.prices[0].taxGroupName", ""),
                groupId: get(item, "product.prices[0].taxGroupId", ""),
                totalCentAmount: get(item, "product.prices[0].centVATAmount", ""),
                totalPercentage: get(item, "product.prices[0].taxPercentage", ""),
                detailPercentage: "{}",
                detailAmount: "{}",
            },
        ],
    };
};

export const getProductProperties = (item, langCode) => {
    return {
        description: get(
            item,
            `product.description[${langCode}].default`,
            get(item, "product.description.sv.default")
        ),
        eans: get(item, "product.articleNumbers.eans", []),
        externalId: null,
        externalReference: get(item, "product.externalReference", ""),
        groupId: get(item, "product.productGroup.productGroupId", ""),
        groupName: get(
            item,
            `product.productGroup.name[${langCode}]`,
            get(item, "product.productGroup.name.sv")
        ),
        imageURL: get(item, "product.images.default", ""),
        isFlexiblePrice: false,
        isService: get(item, "product.isService", false),
        name: get(item, `product.name[${langCode}]`, get(item, "product.name.sv")),
        productId: get(item, "product.productId", ""),
        quantityPrecision: get(item, "product.quantityPrecision", 0),
        sku: get(item, "product.sku", ""),
        topGroupId: get(item, "product.productGroup.topProductGroupId", ""),
        topGroupName: get(
            item,
            `product.productGroup.topProductGroupName[${langCode}]`,
            get(item, "product.productGroup.topProductGroupName.sv")
        ),
        type: get(item, "product.type", ""),
        unitOfMeasure: get(item, "product.unitOfMeasure", ""),
    };
};

export const getStoreInformation = async (store, setStore, setLoading, storeDomain) => {
    await getEndpointPromise(
        `${WEB_ORDER_URL}/store/${get(store, "storeId")}?fetchWebStoreCatalog=true`,
        {
            headers: { storeDomain: storeDomain },
        }
    )
        .then((response) => {
            if (store != response) {
                setStore(response);
            }
            setLoading(false);
        })
        .catch(() => setLoading(false));
};

export const getLicenseId = (store) => {
    return {
        webOrderLicenseId: store.webOrderLicenseId ?? null,
    };
};

export const getOrderMetadata = (store) => {
    return getLicenseId(store);
};
