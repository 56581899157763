import { Badge, Button, Container, useMediaQuery, withWidth } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { get, map, sum } from "lodash-es";
import { getLanguageCode } from "@utils/langUtil";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { AppContext } from "@contextProviders/AppContextProvider";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import FooterBar from "@shared/FooterBar";
import Header from "./Header";
import PropTypes from "prop-types";
import ShoppingBasketOutlinedIcon from "@material-ui/icons/ShoppingBasketOutlined";
import WOButtonGroup from "@shared/FormFields/WOButtonGroup";
import clsx from "clsx";
import { formatCurrency } from "@utils/numberFormatUtil";
import { makeStyles } from "@material-ui/core/styles";
import { setCheckoutSubmitted } from "src/store/checkoutSlice";
import { setDeliveryInfoSubmitted } from "src/store/deliveryInfoSlice";
import CookieBanner from "@shared/CookieBanner";
import { getIsCookieAccept, SELECTED_STORE, setSelectedStore } from "@utils/localStorageUtil";
import CopyrightFooter from "@shared/CopyrightFooter";
import TopBar from "@shared/TopBar";
import { useSnackbar } from "notistack";
import { getStoreInformation, ORDER_TYPES, WEB_ORDER_DELIVERY_TYPES } from "@utils/commonUtil";
import theme from "@config/theme";
import StoreSelector from "@shared/StoreSelector";
import OrderTypeSelector from "@shared/OrderTypeSelector";
import TableSelector from "@shared/TableSelector";
import useLocalStorage from "@rehooks/local-storage";
import { getTranslation } from "src/store/languageSlice";

const useStyles = makeStyles((t) => ({
    container: {
        [t.breakpoints.up("sm")]: {
            marginTop: 30,
        },
        position: "relative",
    },
    contentContainer: {
        backgroundColor: t.palette.background.paper,
        overflowY: "scroll",
        paddingBottom: 49,
    },
    contentDesktopView: {
        overflowY: "scroll",
        height: "calc(100vh - 200px)",
    },
    contentMobileView: {
        marginBottom: 74,
    },
    contentHeightWithBoth: {
        [t.breakpoints.down("xs")]: {
            height: "calc(100vh - 128px)",
        },
    },
    contentHeightWithOnlyHeaderOrFooter: {
        [t.breakpoints.up("xs")]: {
            height: "calc(100vh - 114px)",
        },
        [t.breakpoints.down("xs")]: {
            height: "calc(100vh - 64px)",
        },
    },
    contentHeightWithNone: {
        [t.breakpoints.down("xs")]: {
            height: "100vh",
        },
    },
    footerButton1: {
        display: "inline",
        fontSize: 16,
        fontWeight: 600,
        borderColor: "#FFF !important",
    },
    footerButton1LeftSpan: {
        float: "left",
    },
    footerButton1RightSpan: {
        float: "right",
    },
    footerButton2: {
        width: "fit-content",
        fontSize: 16,
        fontWeight: 600,
    },
    badge: {
        backgroundColor: "transparent",
    },
}));

const Layout = (props) => {
    const langCode = getLanguageCode();
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const deliveryPageInfo = useSelector(({ deliveryInfo }) => deliveryInfo);
    const t = useSelector(getTranslation);
    const { enqueueSnackbar } = useSnackbar();
    const [selectedStore] = useLocalStorage(SELECTED_STORE);

    const { children, width, location } = props;
    const {
        organization,
        cart,
        store,
        setStoreOpen,
        setStore,
        setLoading,
        storeDomain,
        storeList,
        orderType,
        selectedTable,
    } = useContext(AppContext);
    const { countryCode, currencyCode } = organization;
    const showHeader = width !== "xs" || location.pathname === "/";
    const showFooter =
        get(cart, "products.length", 0) > 0 &&
        !location.pathname.includes("payment") &&
        !location.pathname.includes("terms-and-conditions") &&
        !location.pathname.includes("view-receipt") &&
        !location.pathname.includes("view-order-status") &&
        !location.pathname.includes("privacy-policy");
    const cartProducts = get(cart, "products", []);
    const hideCookieBanner = getIsCookieAccept();
    const showCopyrightFooter = !location.pathname.includes("payment");
    const showTopBar = !location.pathname.includes("terms-and-conditions");
    const storeOpen = get(store, "webStoreStatus") === "open" ? true : false;
    const mobileView = useMediaQuery(theme.breakpoints.down("xs"));
    const showStoreSelector = selectedStore || storeList.length === 1 ? false : true;

    const hideTableAndOrderTypeSelector = [
        "/payment-complete",
        "/view-order-status",
        "/view-receipt",
    ].includes(location.pathname);
    const showOrderTypeSelector =
        !orderType &&
        get(store, "webOrderDeliveryType") === WEB_ORDER_DELIVERY_TYPES.takeAwayAndEatIn &&
        !hideTableAndOrderTypeSelector
            ? true
            : false;

    const showTableSelector =
        orderType === ORDER_TYPES.eatIn &&
        !selectedTable &&
        [WEB_ORDER_DELIVERY_TYPES.takeAwayAndEatIn, WEB_ORDER_DELIVERY_TYPES.eatIn].includes(
            get(store, "webOrderDeliveryType")
        ) &&
        !hideTableAndOrderTypeSelector
            ? true
            : false;

    let contentHeightClass = "";
    if (showHeader && showFooter) {
        contentHeightClass = classes.contentHeightWithBoth;
    } else if (showHeader || showFooter) {
        contentHeightClass = classes.contentHeightWithOnlyHeaderOrFooter;
    } else {
        contentHeightClass = classes.contentHeightWithNone;
    }

    const handleDeliveryInfoSubmission = () => {
        //fetch store status before moving to payment window
        getStoreInformation(store, setStore, setLoading, storeDomain);
        if (storeOpen) {
            dispatch(setDeliveryInfoSubmitted(true));
        } else {
            setStoreOpen(false);
            enqueueSnackbar(t.shop_closed, { variant: "error" });
        }
    };

    const handleCheckoutSubmission = () => {
        if (storeOpen) {
            if (sum(map(cartProducts, "totalCentAmount")) > 0) {
                dispatch(setCheckoutSubmitted(true));
            } else {
                enqueueSnackbar(t.total_should_be_more_than_zero, { variant: "error" });
            }
        } else {
            setStoreOpen(false);
            enqueueSnackbar(t.shop_closed, { variant: "error" });
        }
    };

    const handleProductsCheckout = () => {
        if (storeOpen) {
            history.push("/checkout");
        } else {
            setStoreOpen(false);
            enqueueSnackbar(t.shop_closed, { variant: "error" });
        }
    };

    useEffect(() => {
        if (!selectedStore && storeList.length === 1) {
            setSelectedStore(get(storeList, "[0].storeId", ""));
        }
    }, []);

    const FooterToCheckout = () => {
        return (
            <WOButtonGroup>
                <Button
                    className={classes.footerButton1}
                    onClick={handleProductsCheckout}
                    disabled={!storeOpen}
                >
                    {storeOpen ? (
                        <>
                            <span className={classes.footerButton1LeftSpan}>{t.checkout}</span>
                            <span className={classes.footerButton1RightSpan}>
                                {formatCurrency(
                                    (sum(map(cartProducts, "totalCentAmount")) / 100).toFixed(2),
                                    langCode,
                                    countryCode,
                                    currencyCode
                                )}
                            </span>
                        </>
                    ) : (
                        <span className={classes.footerButton1LeftSpan}>{t.closed_for_orders}</span>
                    )}
                </Button>
                <Button
                    className={classes.footerButton2}
                    onClick={handleProductsCheckout}
                    disabled={!storeOpen}
                >
                    <Badge
                        badgeContent={sum(map(cartProducts, "quantity"))}
                        color="primary"
                        className={classes.badge}
                    >
                        <ShoppingBasketOutlinedIcon />
                    </Badge>
                </Button>
            </WOButtonGroup>
        );
    };

    const FooterToPayment = () => {
        return (
            <WOButtonGroup>
                <Button
                    disabled={!deliveryPageInfo.deliveryAddressValid || !storeOpen}
                    className={classes.footerButton1}
                    onClick={handleDeliveryInfoSubmission}
                >
                    {storeOpen ? (
                        <>
                            <span className={classes.footerButton1LeftSpan}>{t.for_payment}</span>
                            <span className={classes.footerButton1RightSpan}>
                                {formatCurrency(
                                    (sum(map(cartProducts, "totalCentAmount")) / 100).toFixed(2),
                                    langCode,
                                    countryCode,
                                    currencyCode
                                )}
                            </span>
                        </>
                    ) : (
                        <span className={classes.footerButton1LeftSpan}>{t.closed_for_orders}</span>
                    )}
                </Button>
                <Button
                    disabled={!deliveryPageInfo.deliveryAddressValid || !storeOpen}
                    className={classes.footerButton2}
                    onClick={handleDeliveryInfoSubmission}
                >
                    <ArrowForwardIosIcon />
                </Button>
            </WOButtonGroup>
        );
    };

    const FooterToDeliveryDetails = () => {
        return (
            <WOButtonGroup>
                <Button
                    className={classes.footerButton1}
                    onClick={handleCheckoutSubmission}
                    disabled={!storeOpen}
                >
                    {storeOpen ? (
                        <>
                            <span className={classes.footerButton1LeftSpan}>
                                {t.to_delivery_info}
                            </span>
                        </>
                    ) : (
                        <span className={classes.footerButton1LeftSpan}>{t.closed_for_orders}</span>
                    )}
                </Button>
                <Button
                    className={classes.footerButton2}
                    onClick={handleCheckoutSubmission}
                    disabled={!storeOpen}
                >
                    <ArrowForwardIosIcon />
                </Button>
            </WOButtonGroup>
        );
    };

    let FooterComponent = FooterToCheckout;
    if (location.pathname.includes("checkout")) {
        FooterComponent = FooterToDeliveryDetails;
    } else if (location.pathname.includes("delivery-info")) {
        FooterComponent = FooterToPayment;
    }

    return (
        <Container
            id="main-container"
            maxWidth={"sm"}
            className={classes.container}
            disableGutters
            fixed
        >
            {showTopBar && <TopBar />}
            {showHeader && <Header {...props} />}
            <div
                className={
                    mobileView
                        ? clsx(classes.contentContainer, showFooter && classes.contentMobileView)
                        : clsx(
                              classes.contentContainer,
                              contentHeightClass,
                              classes.contentDesktopView
                          )
                }
            >
                {children}
                {showCopyrightFooter && <CopyrightFooter />}
            </div>
            {showFooter && <FooterBar content={<FooterComponent />} />}
            {showTableSelector && !showOrderTypeSelector && <TableSelector />}
            {showOrderTypeSelector && !showStoreSelector && <OrderTypeSelector />}
            {showStoreSelector && <StoreSelector />}
            {!hideCookieBanner && <CookieBanner />}
        </Container>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    width: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
};

export default withWidth()(Layout);
