import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { getTranslation } from "src/store/languageSlice";

const useStyles = makeStyles((t) => ({
    container: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -70%)",
        textAlign: "center",
        width: "60vw",
        [t.breakpoints.up("sm")]: { width: "40vw" },
        [t.breakpoints.up("md")]: { width: "20vw" },
    },
    img: {
        width: "100%",
    },
}));

const NotFound = () => {
    const classes = useStyles();
    const t = useSelector(getTranslation);
    return (
        <div className={classes.container}>
            <img src={"/assets/images/yabie-logo.svg"} alt={t.yabie_ab} className={classes.img} />
            <h2>{t.page_not_found_title}</h2>
            <h4>{t.page_not_found_info}</h4>
        </div>
    );
};

export default React.memo(NotFound);
