import { IconButton, Typography } from "@material-ui/core";

import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import PropTypes from "prop-types";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import OrderTypeBar from "../OrderTypeBar";

const useStyles = makeStyles((theme) => ({
    topBar: {
        height: 64,
    },
    topBarContentContainer: {
        margin: 0,
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)",
    },
    titleContainer: {
        position: "absolute",
        marginTop: 8,
        display: "inline-block",
        width: "calc(100% - 100px)",
    },
    title: {
        fontSize: 22,
        fontWeight: 600,
        textAlign: "center",
    },
    backButton: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: 25,
    },
    secondaryActionContainer: {
        float: "right",
        marginTop: 10,
        marginRight: 16,
    },
}));

const TitleBar = (props) => {
    const classes = useStyles();
    const { title, handleBackClick, secondaryActionComponent = null } = props;

    return (
        <>
            <div className={classes.topBar}>
                <div className={classes.topBarContentContainer}>
                    <IconButton color="primary" aria-label="back" onClick={handleBackClick}>
                        <KeyboardBackspaceIcon className={classes.backButton} />
                    </IconButton>
                    <span className={classes.titleContainer}>
                        <Typography className={classes.title} noWrap>
                            {title}
                        </Typography>
                    </span>
                    {secondaryActionComponent && (
                        <span className={classes.secondaryActionContainer}>
                            {secondaryActionComponent}
                        </span>
                    )}
                </div>
            </div>
            <OrderTypeBar />
        </>
    );
};

TitleBar.propTypes = {
    title: PropTypes.string.isRequired,
    handleBackClick: PropTypes.func.isRequired,
    secondaryActionComponent: PropTypes.node,
};

export default React.memo(TitleBar);
