import { createSlice } from "@reduxjs/toolkit";

export const checkoutSlice = createSlice({
    name: "checkout",
    initialState: {
        checkoutSubmitted: false,
    },
    reducers: {
        setCheckoutSubmitted: (state, action) => {
            state.checkoutSubmitted = action.payload;
        },
    },
});

export const { setCheckoutSubmitted } = checkoutSlice.actions;

export default checkoutSlice.reducer;
