import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { fetchLanguageTranslation } from "./langUtil";
import { getTranslation } from "src/store/languageSlice";

const useStyles = makeStyles({
    container: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        textAlign: "center",
    },
});

// Created a layer to check weather user have enable cookies in browser
export const LocalStorageCheck = (props) => {
    const classes = useStyles();
    const t = useSelector(getTranslation);
    const dispatch = useDispatch();

    useEffect(() => {
        if (isEmpty(t)) {
            dispatch(fetchLanguageTranslation());
        }
    }, []);

    if (navigator.cookieEnabled) {
        return <>{props.children}</>;
    } else {
        return (
            <div className={classes.container}>
                <h4>{t.enable_cookies_to_access}</h4>
            </div>
        );
    }
};

LocalStorageCheck.propTypes = {
    children: PropTypes.node.isRequired,
};
