import {
    Dialog,
    DialogContent,
    DialogTitle,
    Grid,
    Typography,
    useMediaQuery,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import CoverImage from "@shared/CoverImage";
import WOButton from "@shared/FormFields/WOButton";
import WOButtonGroup from "@shared/FormFields/WOButtonGroup";
import TitleBar from "@shared/TitleBar";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { AppContext } from "@contextProviders/AppContextProvider";
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import { getTranslation } from "src/store/languageSlice";

export const useStyles = makeStyles((theme) => ({
    dialogTitle: {
        flex: "0 0 auto",
        margin: 0,
        padding: "0px 24px",
        [theme.breakpoints.down("xs")]: {
            padding: 0,
        },
    },
    dialogContent: {
        minWidth: 500,
        paddingRight: 10,
        paddingLeft: 10,
        [theme.breakpoints.down("xs")]: {
            paddingTop: 0,
            marginBottom: 0,
            minWidth: "100vw",
        },
    },
    contentSection: {
        marginTop: 15,
        padding: 5,
    },
    address: {
        fontSize: 16,
    },
    title: {
        fontSize: 20,
        fontWeight: "bold",
    },
    days: {
        fontSize: 16,
    },
    weekSchedule: {
        marginTop: 10,
    },
    contactItem: {
        fontWeight: "bold",
    },
}));
const StoreInfo = (props) => {
    const { open, handleClose } = props;
    const theme = useTheme();
    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
    const { store, organization } = useContext(AppContext);
    const t = useSelector(getTranslation);

    const openGoogleMaps = () => {
        window.open(`https://maps.google.com/?q=${get(store, "lat", "")},${get(store, "lon", "")}`);
    };

    const handleStoreClose = () => {
        handleClose(false);
    };

    const getWeekSchedule = () => {
        const weekDays = [
            "monday",
            "tuesday",
            "wednesday",
            "thursday",
            "friday",
            "saturday",
            "sunday",
        ];
        return (
            <>
                {weekDays.map((day) => (
                    <Grid container key={day}>
                        <Grid item xs={6}>
                            <Typography className={classes.days}>{t[day]}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography className={classes.days} color="secondary" align="right">
                                {!isEmpty(get(store, `webStoreWorkHours.${day}.openingTime`)) &&
                                !isEmpty(get(store, `webStoreWorkHours.${day}.closingTime`, ""))
                                    ? `${get(store, `webStoreWorkHours.${day}.openingTime`, "")} -
								${get(store, `webStoreWorkHours.${day}.closingTime`, "")}`
                                    : t.no_time_frame}
                            </Typography>
                        </Grid>
                    </Grid>
                ))}
            </>
        );
    };

    return (
        <div>
            <Dialog fullScreen={fullScreen} open={open} onClose={handleStoreClose}>
                <DialogTitle id="responsive-dialog-title" className={classes.dialogTitle}>
                    <TitleBar
                        title={`${t.about} ${get(store, "name", "")}`}
                        handleBackClick={handleStoreClose}
                    />
                </DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    {store.heroBannerUrl ? (
                        <CoverImage coverImageUrl={store.heroBannerUrl} />
                    ) : (
                        <></>
                    )}
                    <Grid container className={classes.contentSection}>
                        <Grid item xs={8}>
                            <Typography className={classes.title}>{t.address}</Typography>
                            <Typography className={classes.address}>
                                {get(store, "addressObject.street", "")}
                            </Typography>
                            <Typography className={classes.address}>
                                {get(store, "addressObject.postalCode", "")}{" "}
                                {get(store, "addressObject.city", "")}
                            </Typography>
                        </Grid>
                        <Grid item xs={4}></Grid>
                    </Grid>
                    <Grid container className={classes.contentSection}>
                        <Grid item xs={12}>
                            <WOButtonGroup>
                                <WOButton
                                    handleClick={() => openGoogleMaps()}
                                    size="large"
                                    style={{
                                        width: "100%",
                                        backgroundColor: get(store, "buttonColor", "secondary"),
                                        color: get(store, "buttonTextColor", "inherit"),
                                    }}
                                >
                                    <LocationOnIcon />
                                    {t.find_us}
                                </WOButton>
                            </WOButtonGroup>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.contentSection}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>{t.contact}</Typography>
                            <Typography>
                                <span className={classes.contactItem}>{`${t.tel}: `}</span>
                                <a href={`tel:${get(store, "phoneNumber", "")}`}>
                                    {get(store, "phoneNumber", "")}
                                </a>
                            </Typography>
                            <Typography>
                                <span className={classes.contactItem}>{`${t.orgNumber}: `}</span>
                                {get(organization, "organizationNumber", "")}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid container className={classes.contentSection}>
                        <Grid item xs={12}>
                            <Typography className={classes.title}>
                                {t.open_hours_for_online_shopping}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={classes.weekSchedule}>
                            {getWeekSchedule()}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );
};

StoreInfo.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default StoreInfo;
