import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

import { grey } from "@material-ui/core/colors";

// Create a MUI theme instance.
let theme = createMuiTheme({
    palette: {
        primary: {
            main: "#000",
        },
        secondary: {
            main: "#3F8D41",
        },
        background: {
            default: grey[300],
            paper: "#FFF",
        },
        textInput: {
            icon: grey[500],
            border: grey[500],
        },
        categoryButton: {
            boxShadow: "0px 0px 2px rgb(0 0 0 / 30%)",
            border: 0,
        },
        swedBank: {
            caption: "#855956",
        },
        link: {
            color: "#3384CF",
        },
        mainBanner: {
            color: "#FFF",
            fontFamily: [
                "Quicksand",
                "Roboto",
                '"Helvetica Neue"',
                '"Helvetica, Arial"',
                "sans-serif",
            ].join(","),
            backGroundColor: "#000",
        },
        action: {
            disabledBackground: "#A9A9A9",
            disabled: "#FFF",
        },
    },
    typography: {
        fontFamily: [
            "Quicksand",
            "Roboto",
            '"Helvetica Neue"',
            '"Helvetica, Arial"',
            "sans-serif",
        ].join(","),
        button: {
            textTransform: "none",
        },
    },
    overrides: {
        MuiBadge: {
            colorPrimary: {
                backgroundColor: "transparent",
            },
        },
        MuiButton: {
            containedSecondary: {
                color: "#FFF",
                backgroundColor: "#3F8D41",
            },
        },
    },
});
theme = responsiveFontSizes(theme);
export default theme;
