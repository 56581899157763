import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PropTypes from "prop-types";
import { Grid, IconButton, Typography, useMediaQuery } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { get } from "lodash-es";
import { AppContext } from "@contextProviders/AppContextProvider";
import { setSelectedStore } from "@utils/localStorageUtil";
import clsx from "clsx";
import StoreInfo from "@shared/StoreInfo";
import theme from "@config/theme";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import AddCircleOutlineIcon from "@material-ui/icons//AddCircleOutline";
import { getTranslation } from "src/store/languageSlice";
import { getLanguageCode, languageCodes, setLocale } from "@utils/langUtil";

const useStyles = makeStyles({
    container: {
        position: "relative",
    },
    closeButton: {
        right: 5,
        top: 10,
        position: "absolute",
    },
    closeIconBar: {
        height: 50,
    },
    itemContainer: {
        paddingLeft: 30,
    },
    listIcon: {
        right: 0,
        position: "absolute",
        display: "contents",
    },
    selectedMenuItem: {
        backgroundColor: "#F5F5F5",
    },
    footerText: {
        bottom: 10,
        position: "absolute",
        textAlign: "right",
        right: 10,
    },
    link: {
        fontWeight: 600,
        color: "inherit",
    },
    topItem: {
        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
    },
});

const SideMenu = (props) => {
    const classes = useStyles();
    const { open, handleClose } = props;
    const t = useSelector(getTranslation);
    const { store, storeList, removeCart } = useContext(AppContext);
    const [selectStoreOpen, setSelectStoreOpen] = useState(false);
    const [selectLanguageOpen, setSelectLanguageOpen] = useState(false);
    const [showStoreInfo, setShowStoreInfo] = useState(false);
    const mobileView = useMediaQuery(theme.breakpoints.down("xs"));

    const handleStoreSelection = async (storeId) => {
        handleClose(false);
        setSelectedStore(storeId);
        if (get(store, "storeId") !== storeId) {
            removeCart();
            window.location.reload();
        }
    };

    const openGoogleMaps = () => {
        window.open(`https://maps.google.com/?q=${get(store, "lat", "")},${get(store, "lon", "")}`);
    };

    const langCode = getLanguageCode();
    const handleLanguageChange = (value) => {
        if (languageCodes[value] !== langCode) {
            setLocale(value);
        }
    };

    const content = () => (
        <>
            <div className={classes.container}>
                <Grid container>
                    <Grid item xs={12} className={classes.closeIconBar}>
                        <IconButton
                            aria-label="store info"
                            color="inherit"
                            onClick={handleClose}
                            className={classes.closeButton}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Grid>

                    <Grid item xs={12}>
                        <List>
                            {storeList.length !== 1 && (
                                <>
                                    <ListItem
                                        button
                                        divider={true}
                                        onClick={() => setSelectStoreOpen(!selectStoreOpen)}
                                        className={
                                            selectStoreOpen
                                                ? clsx(classes.selectedMenuItem, classes.topItem)
                                                : classes.topItem
                                        }
                                    >
                                        <ListItemText primary={t.choose_restaurant} />
                                        <ListItemIcon className={classes.listIcon}>
                                            {selectStoreOpen ? (
                                                <RemoveCircleOutlineIcon />
                                            ) : (
                                                <AddCircleOutlineIcon />
                                            )}
                                        </ListItemIcon>
                                    </ListItem>
                                    {selectStoreOpen &&
                                        storeList.map((storeElement) => (
                                            <ListItem
                                                key={store.name}
                                                button
                                                onClick={() =>
                                                    handleStoreSelection(
                                                        get(storeElement, "storeId")
                                                    )
                                                }
                                                className={
                                                    get(storeElement, "storeId") ===
                                                    get(store, "storeId")
                                                        ? clsx(
                                                              classes.selectedMenuItem,
                                                              classes.itemContainer
                                                          )
                                                        : classes.itemContainer
                                                }
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Typography>
                                                            {get(storeElement, "name")}
                                                        </Typography>
                                                    }
                                                />
                                            </ListItem>
                                        ))}
                                </>
                            )}
                            <ListItem
                                button
                                divider={true}
                                onClick={() => setSelectLanguageOpen((prev) => !prev)}
                                className={clsx(
                                    selectLanguageOpen ? classes.selectedMenuItem : "",
                                    storeList.length === 1 ? classes.topItem : ""
                                )}
                            >
                                <ListItemText primary={t.select_language} />
                                <ListItemIcon className={classes.listIcon}>
                                    {selectLanguageOpen ? (
                                        <RemoveCircleOutlineIcon />
                                    ) : (
                                        <AddCircleOutlineIcon />
                                    )}
                                </ListItemIcon>
                            </ListItem>
                            {selectLanguageOpen &&
                                Object.keys(languageCodes).map((language) => (
                                    <ListItem
                                        key={language}
                                        button
                                        onClick={() => handleLanguageChange(language)}
                                        className={clsx(
                                            classes.itemContainer,
                                            languageCodes[language] === langCode
                                                ? classes.selectedMenuItem
                                                : ""
                                        )}
                                    >
                                        <ListItemText
                                            primary={
                                                <Typography>
                                                    {language === "SE" ? t.swedish : t.english}
                                                </Typography>
                                            }
                                        />
                                    </ListItem>
                                ))}
                            <ListItem button onClick={openGoogleMaps} divider={true}>
                                <ListItemText primary={t.find_us} />
                            </ListItem>
                            <ListItem button onClick={() => setShowStoreInfo(true)} divider={true}>
                                <ListItemText primary={`${t.about} ${get(store, "name", "")}`} />
                            </ListItem>
                        </List>
                        <StoreInfo open={showStoreInfo} handleClose={setShowStoreInfo} />
                    </Grid>
                </Grid>
            </div>
            <Grid container>
                <Grid item xs={12} className={classes.footerText}>
                    <Typography>
                        <span>{`${t.powered_by} `}</span>
                        <a
                            className={classes.link}
                            href="https://www.yabie.com/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            {t.yabie_ab}
                        </a>
                    </Typography>
                </Grid>
            </Grid>
        </>
    );

    return (
        <div>
            <Drawer
                PaperProps={{ style: { position: "absolute", width: "80%" } }}
                BackdropProps={{ style: { position: "absolute" } }}
                ModalProps={{
                    ...(!mobileView && { container: document.getElementById("main-container") }),
                    style: { position: "absolute" },
                }}
                open={open}
                onClose={handleClose}
                variant="temporary"
                transitionDuration={{ exit: 0, enter: 500 }}
            >
                {content()}
            </Drawer>
        </div>
    );
};

SideMenu.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
};

export default SideMenu;
